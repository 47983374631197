import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';
import Button from 'Components/Button/Button';
import Header from 'Components/Header/Header';
import Input from 'Components/Input/Input';
import { loginRequest } from 'Store/Auth/AuthActions';
import { getAppFetchingSelector } from 'Store/AppFetching/AppFetchingSelector';
import { getUserSelector } from 'Store/User/UserSelector';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useInjectSaga } from 'redux-injectors';
import saga from './LoginSaga';
import './LoginStyles.scss';

const key = 'login';

export default props => {
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const isFetching = useSelector(getAppFetchingSelector);

  const user = useSelector(getUserSelector);

  const onToggleVisiblePassword = () => setShowPassword(!showPassword);

  const onSubmit = values => dispatch(loginRequest(values));

  const { location } = props;
  let email = '';
  if (location && location.search) {
    const paramsUrl = new URLSearchParams(location.search);
    email = paramsUrl.get('email');
  }

  const renderForm = () => (
    <Formik
      initialValues={{ email, password: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
        password: Yup.string().required('Required'),
      })}
      onSubmit={onSubmit}
      render={props => {
        const {
          handleChange, handleBlur, errors, handleSubmit, values,
        } = props;
        const disabled = !isEmpty(errors);

        const eyeClass = showPassword ? 'fa-eye-slash' : 'fa-eye';
        return (
          <form className="Login__form" onSubmit={handleSubmit}>
            {email ? (<h1>Welcome back, look like you already have an account</h1>) : (<h1>Login</h1>)}
            {email && (
              <div className="Login__form__link">
                Just enter your previous password to login and start The Fitt Cycle again.
                <br />
                If you've forgotten password
{' '}
<a href={`/password/forgot?email=${email}`}>reset your password.</a>
              </div>
            )}
            <Input
              className="Login__form__email"
              name="email"
              type="email"
              placeholder="Email"
              autoCapitalize="words"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email}
            />

            <Input
              className="Login__form__password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password}
              RightComponent={<span onClick={onToggleVisiblePassword} className={`fa ${eyeClass}`} />}
            />
            {!email && (
              <Link
                className="Login__form__link"
                to={`${user && user.email
                  ? `/password/forgot?email=${user.email}` : '/password/forgot'}`}
              >
                Forgot password?
              </Link>
            )}
            <Button
              className="Login__form__button"
              type="submit"
              loading={isFetching}
              disabled={disabled}
            >
                Login
            </Button>
          </form>
        );
      }}
    />
  );


  return (
    <div className="Login">
      <Header RightComponent={<Link to="/signUp" className="link">Sign Up</Link>} />
      <div className="container">
        <div className="col-lg-7 offset-lg-3 col-sm-10">{renderForm()}</div>
        <div className="col-lg-7 offset-lg-3 col-sm-10 text-center">
          Need Help? We're happy to! Contact
{' '}
<a target="_blank" href="mailto:support@thefittcycle.com">support@thefittcycle.com</a>
        </div>
      </div>
    </div>
  );
};
