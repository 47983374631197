import React, { useState } from 'react';
import Button from 'Components/Button/Button';
import ButtonLogout from 'Components/Button/ButtonLogout';
import Header from 'Components/Header/Header';
import Constants from 'Utils/Constants';
import { useSelector, useDispatch } from 'react-redux';
import { getUserSelector } from 'Store/User/UserSelector';
import { updateGenderRequest } from 'Store/User/UserActions';
import { getAppFetchingSelector } from 'Store/AppFetching/AppFetchingSelector';
import { getUserName } from 'Utils/Utils';
import { useInjectSaga } from 'redux-injectors';
import saga from './SignUp1Saga';
import './SignUp1Styles.scss';

const key = 'signup1';

export default () => {
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const isFetching = useSelector(getAppFetchingSelector);
  const user = useSelector(getUserSelector);
  const defaultGender = user && (user.gender === 'male' || user.gender === 'female') ? user.gender : 'female';
  const [gender, setGender] = useState(defaultGender);
  const userName = getUserName(user);

  const onContinue = event => {
    event.preventDefault();
    dispatch(updateGenderRequest(gender));
  };

  return (
    <div className="SignUp1">
      <Header RightComponent={<ButtonLogout />} />
      <div className="container">
        <div className="col-lg-5 col-md-8 col-sm-12 align-self-center mx-auto">
          <h6>Step 1 of 6 </h6>
          <h1>{`Hello ${userName}, what’s your gender?`}</h1>
          <div className="row justify-content-center">
            {Constants.GENDER.map((item, index) => {
              const icon = item === 'female' ? 'fa fa-mars fa-2x' : 'fa fa-venus fa-2x';
              const active = gender === item;
              const activeClass = active ? 'active' : '';
              const text = item === 'male' ? 'man' : 'woman';
              return (
                <div key={index} className="col-6 d-flex flex-column align-items-center SignUp1__selector">
                  <div
                    key={index}
                    className={`row justify-content-center align-items-center ${activeClass}`}
                    onClick={() => setGender(item)}
                  >
                    <i className={`${icon} ${activeClass}`} />
                  </div>
                  <div className={activeClass}>{text}</div>
                </div>
              );
            })}
          </div>
          <Button
            type="button"
            loading={isFetching}
            onClick={onContinue}
          >
              Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
