import { put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { UPDATE_GENDER_REQUEST } from 'Store/User/UserActionTypes';
import { appFetchingStart, appFetchingEnd } from 'Store/AppFetching/AppFetchingActions';


function* updateGenderSaga() {
  yield put(appFetchingStart());
  yield delay(1000);
  yield put(appFetchingEnd());
  yield put(push('/signup/2'));
}


export default function* signup1Watcher() {
  yield takeLatest(UPDATE_GENDER_REQUEST, updateGenderSaga);
}
