export const GET_INVOICES_REQUEST = 'YOUR_ACCOUNT/GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'YOUR_ACCOUNT/GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'YOUR_ACCOUNT/GET_INVOICES_FAILURE';

export const CHECKOUT_REQUEST = 'YOUR_ACCOUNT/CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'YOUR_ACCOUNT/CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'YOUR_ACCOUNT/CHECKOUT_FAILURE';

export const GET_SUBSCRIPTION_REQUEST = 'YOUR_ACCOUNT/GET_SUBSCRIPTION_REQUEST';
export const GET_SUBSCRIPTION_SUCCESS = 'YOUR_ACCOUNT/GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAILURE = 'YOUR_ACCOUNT/GET_SUBSCRIPTION_FAILURE';

export const CANCEL_SUBSCRIPTION_REQUEST = 'YOUR_ACCOUNT/CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'YOUR_ACCOUNT/CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'YOUR_ACCOUNT/CANCEL_SUBSCRIPTION_FAILURE';

export const DOWNLOAD_INVOICE_REQUEST = 'YOUR_ACCOUNT/DOWNLOAD_INVOICE_REQUEST';
export const DOWNLOAD_INVOICE_SUCCESS = 'YOUR_ACCOUNT/DOWNLOAD_INVOICE_SUCCESS';
export const DOWNLOAD_INVOICE_FAILURE = 'YOUR_ACCOUNT/DOWNLOAD_INVOICE_FAILURE';

export const CHANGE_PLAN_REQUEST = 'YOUR_ACCOUNT/CHANGE_PLAN_REQUEST';
export const CHANGE_PLAN_SUCCESS = 'YOUR_ACCOUNT/CHANGE_PLAN_SUCCESS';
export const CHANGE_PLAN_FAILURE = 'YOUR_ACCOUNT/CHANGE_PLAN_FAILURE';


export default {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  DOWNLOAD_INVOICE_REQUEST,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_FAILURE,
  CHANGE_PLAN_REQUEST,
  CHANGE_PLAN_SUCCESS,
  CHANGE_PLAN_FAILURE,
};
