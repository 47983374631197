import PaymentPlanPage from 'Pages/PaymentPlan/PaymentPlanPage';
import DownloadPage from 'Pages/Download/DownloadPage';
import SignUp1Page from 'Pages/SignUp1/SignUp1Page';
import SignUp2Page from 'Pages/SignUp2/SignUp2Page';
import SignUp3Page from 'Pages/SignUp3/SignUp3Page';
import SignUp4Page from 'Pages/SignUp4/SignUp4Page';
import SignUp5Page from 'Pages/SignUp5/SignUp5Page';
import SignUp6Page from 'Pages/SignUp6/SignUp6Page';
import PaymentDetailPage from 'Pages/PaymentDetail/PaymentDetailPage';
import YourAccountPage from 'Pages/YourAccount/YourAccountPage';
import ForgotPasswordPage from 'Pages/ForgotPassword/ForgotPasswordPage';
import LoginPage from 'Pages/Login/LoginPage';
import SignUpPage from 'Pages/SignUp/SignUpPage';
import UpdatePasswordPage from 'Pages/UpdatePassword/UpdatePasswordPage';
import DisclaimerPage from 'Pages/Terms/DisclaimerPage';
import TermAndConditionPage from 'Pages/Terms/TermAndConditionPage';
import PrivacyPage from 'Pages/Terms/PrivacyPage';
import ShareRecipePage from 'Pages/ShareRecipe/ShareRecipePage';
import ShareWorkoutPage from 'Pages/ShareWorkout/ShareWorkoutPage';
import SharePostPage from 'Pages/SharePost/SharePostPage';

export const publicRoutes = [
  {
    path: '/login',
    component: LoginPage,
  },

  {
    path: '/password/forgot',
    component: ForgotPasswordPage,
  },
  {
    path: '/password/update',
    component: UpdatePasswordPage,
  },
  {
    path: '/signup',
    component: SignUpPage,
  },
  {
    path: '/privacy',
    component: PrivacyPage,
  },
  {
    path: '/term',
    component: TermAndConditionPage,
  },
  {
    path: '/disclaimer',
    component: DisclaimerPage,
  },
  {
    path: '/share/recipe',
    component: ShareRecipePage,
  },
  {
    path: '/share/post',
    component: SharePostPage,
  },
  {
    path: '/share/workout',
    component: ShareWorkoutPage,
  },
  {
    path: '/download',
    component: DownloadPage,
  },
];

export const privateRoutes = [
  {
    path: '/',
    component: YourAccountPage,
  },

  {
    path: '/signup/1',
    component: SignUp1Page,
  },
  {
    path: '/signup/2',
    component: SignUp2Page,
  },
  {
    path: '/signup/3',
    component: SignUp3Page,
  },
  {
    path: '/signup/4',
    component: SignUp4Page,
  },
  {
    path: '/signup/5',
    component: SignUp5Page,
  },
  {
    path: '/signup/6',
    component: SignUp6Page,
  },

  {
    path: '/payment/plan',
    component: PaymentPlanPage,
  },
  {
    path: '/payment/detail',
    component: PaymentDetailPage,
  },
];

export default {};
