import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { configureStore } from 'Store/ConfigureStore';
import history from 'Utils/History';
import App from 'Pages/App/AppPage';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';

import 'Assets/scss/app.scss';
import 'Assets/scss/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

export const store = configureStore(history);
const MOUNT_NODE = document.getElementById('root');

Sentry.init({ dsn: 'https://60aa47ec97f842a589c750adcb8ffd2a@sentry.io/1867081' });

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,

  MOUNT_NODE,
);


export default {};
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
