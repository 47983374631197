import { createAction } from 'redux-actions';
import UserActionTypes from './UserActionTypes';

export const updateGenderRequest = createAction(UserActionTypes.UPDATE_GENDER_REQUEST);

export const updateBirthdayRequest = createAction(UserActionTypes.UPDATE_BIRTHDAY_REQUEST);

export const updateHeightRequest = createAction(UserActionTypes.UPDATE_HEIGHT_REQUEST);

export const updateWeightRequest = createAction(UserActionTypes.UPDATE_WEIGHT_REQUEST);

export const updateGoalRequest = createAction(UserActionTypes.UPDATE_GOAL_REQUEST);

export const updateProfileRequest = createAction(UserActionTypes.UPDATE_PROFILE_REQUEST);
export const updateProfileSuccess = createAction(UserActionTypes.UPDATE_PROFILE_SUCCESS);
export const updateProfileFailure = createAction(UserActionTypes.UPDATE_PROFILE_FAILURE);

export const resetPasswordRequest = createAction(UserActionTypes.RESET_PASSWORD_REQUEST);
export const resetPasswordSuccess = createAction(UserActionTypes.RESET_PASSWORD_SUCCESS);
export const resetPasswordFailure = createAction(UserActionTypes.RESET_PASSWORD_FAILURE);

export const updatePasswordRequest = createAction(UserActionTypes.UPDATE_PASSWORD_REQUEST);
export const updatePasswordSuccess = createAction(UserActionTypes.UPDATE_PASSWORD_SUCCESS);
export const updatePasswordFailure = createAction(UserActionTypes.UPDATE_PASSWORD_FAILURE);

export default {
  updateGenderRequest,
  updateBirthdayRequest,
  updateHeightRequest,
  updateWeightRequest,
  updateGoalRequest,

  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure,

  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,

  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFailure,
};
