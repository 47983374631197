import React from 'react';
import {
  TabContent, TabPane, Table, Alert, Modal, ModalFooter, ModalBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getUserSelector } from 'Store/User/UserSelector';
import { getPlanSelector } from 'Store/Plan/PlanSelector';
import { getAppSubscriptionSelector } from 'Store/AppleSubscription/AppleSubscriptionSelector';
import { injectReducer, injectSaga } from 'redux-injectors';
import { logoutRequest } from 'Store/Auth/AuthActions';
import {
  formatPlanDescription, formatMoneyString, isValidSubscription, getCanceledTime, reportCrash,
} from 'Utils/Utils';
import { CBPlan } from 'Types';
import moment from 'moment';
import classnames from 'classnames';
import Constants from 'Utils/Constants';
import UserMenu from 'Components/UserMenu/UserMenu';
import PaymentForm from 'Components/PaymentForm/PaymentForm';
import Spinner from 'Components/Spinner/Spinner';
import Header from 'Components/Header/Header';
import Button from 'Components/Button/Button';
import { createStructuredSelector } from 'reselect';
import PaymentPlanPage from '../PaymentPlan/PaymentPlanPage';
import YourAccountSelector from './YourAccountSelector';
import YourAccountActions from './YourAccountActions';
import reducer from './YourAccountReducer';
import saga from './YourAccountSaga';
import './YourAccountStyles.scss';


class YourAccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      planIndex: -1,
      tempPlanIndex: 0,
      modal: false,
      modalCancelSub: false,
      paymentFormVisible: true,
      subscriptionStatus: 'inactive',
    };
  }

  componentDidMount() {
    const { planIndex } = this.state;
    const {
  currentSubscription, plans, getSubscriptions,
} = this.props;
    getSubscriptions();
    if (currentSubscription && Array.isArray(plans) && plans.length) {
      const index = plans.findIndex(p => p.id === currentSubscription.plan_id);
      if (index !== -1 && planIndex === -1) {
        this.setState({ planIndex: index });
      }
      const isValidSub = isValidSubscription(currentSubscription);
      if (isValidSub) {
        this.setState({ paymentFormVisible: false });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { plans, currentSubscription: oldCurrentSubscription } = this.props;
    const { currentSubscription, isProcessingPayment } = props;
    if (currentSubscription !== oldCurrentSubscription) {
      this.setState({ subscriptionStatus: (currentSubscription && (currentSubscription.status === 'active' || currentSubscription.status === 'in_trial') ? 'active' : 'inactive') });
    }
    if (currentSubscription && Array.isArray(plans) && plans.length) {
      const index = plans.findIndex(p => p.id === currentSubscription.plan_id);
      if (index !== -1) {
        this.setState({ planIndex: index });
      }
      const isValidSub = isValidSubscription(currentSubscription);
      if (isValidSub && !isProcessingPayment) {
        this.setState({ paymentFormVisible: false });
      }
    }
  }

  onOpenApp = () => {
    window.location.href = Constants.MOBILE_DEEPLINK;
  };

  onActiveTabChange = activeTab => {
    this.setState({ activeTab });
    if (activeTab === '2') {
      const { getInvoices } = this.props;
      getInvoices();
    }
  };

  onLogout = () => {
    const { logout } = this.props;
    logout();
  }

  onInvoicePressed = () => this.onActiveTabChange('2');

  onSubscriptionPressed = () => this.onActiveTabChange('1');

  onContactPressed = () => {
    const win = window.open(Constants.HELP_LINK, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  onIndexChanged = index => {
    const { plans, currentSubscription } = this.props;
    const { planIndex } = this.state;
    if (!Array.isArray(plans) || plans.length < 1) {
      return;
    }

    if (index === planIndex) {
      return;
    }


    // const sub = getValidSubscription(subscriptions);

    if (currentSubscription) {
      if (currentSubscription.plan_id === plans[index].id) {
        this.setState({ planIndex: index });
      } else {
        this.setState({ tempPlanIndex: index, modal: true });
      }
    } else {
      this.setState({ planIndex: index });
    }
  };

  onCheckout = (stripe, name) => {
    const {
      plans, user, sendCheckoutFailure, sendCheckoutRequest,
    } = this.props;
    try {
      const { planIndex } = this.state;
      if (!Array.isArray(plans) || plans.length === 0) {
        sendCheckoutFailure('Internal error, while getting plans please refresh this page.');
        return;
      }

      const {
        first_name, last_name, phone, email,
      } = user;

      const customer = {
        first_name, last_name, phone, email,
      };

      if (planIndex < 0) {
        sendCheckoutFailure('Please select a plan');
        return;
      }
      const plan = plans[planIndex];

      const payload = {
        stripe,
        name,
        customer,
        plan,

      };

      sendCheckoutRequest(payload);
    } catch (error) {
      const errorMsg = (error && error.message) || Constants.INTERNAL_ERROR_MESSAGE;
      sendCheckoutFailure(errorMsg);
      reportCrash(error);
    }
  };

  onChangePlan = index => {
    const {
      plans, currentSubscription, sendChangePlanRequest,
    } = this.props;
    const plan = plans[index];

    const needUpdatePlan = currentSubscription && currentSubscription.plan_id !== plan.id;
    if (!needUpdatePlan) return;

    const payload = {
      plan_id: plan.id,
      subscription_id: currentSubscription.id,
    };
    sendChangePlanRequest(payload);
  };


  renderMenu = () => {
    const { user } = this.props;
    return (
      <UserMenu
        user={user}
        onLogout={this.onLogout}
        onOpenAppPressed={this.onOpenApp}
        onInvoicePressed={this.onInvoicePressed}
        onSubscriptionPressed={this.onSubscriptionPressed}
        onContactPressed={this.onContactPressed}
      />
    );
  }

  forceShowUpdatePayment = evt => {
    evt.preventDefault();
    this.setState({ paymentFormVisible: true });
  };

  renderAlertModal = () => {
    const { plans } = this.props;
    const { tempPlanIndex, modal } = this.state;
    const plan: CBPlan = Array.isArray(plans) && plans.length ? plans[tempPlanIndex] : null;
    const planDes = formatPlanDescription(plan);


    return (
      <Modal centered isOpen={modal} toggle={() => this.setState({ modal: false })} className="Account__modal">
        <ModalBody className="Account__modal__body">
          Are you sure you want to change your plan to
              {' '}
          {`${planDes}`}
        </ModalBody>
        <ModalFooter style={{ flexWrap: 'nowrap' }}>
          <div style={{ width: '100%' }} />
          <Button
            style={{ marginRight: '20px', height: '48px' }}
            small
            onClick={() => {
              this.setState({ modal: false, tempPlanIndex });
              this.onChangePlan(tempPlanIndex);
            }}
          >
            Yes
              </Button>
          <Button style={{ height: '48px' }} outline small onClick={() => this.setState({ modal: false })}>No</Button>
        </ModalFooter>
      </Modal>
    );
  };

  renderAlertCancelSubModal = () => {
    const { modalCancelSub } = this.state;
    const { sendCancelSubscriptionRequest, currentSubscription } = this.props;
    return (
      <Modal centered isOpen={modalCancelSub} toggle={() => this.setState({ modalCancelSub: false })} className="Account__modal">
        <ModalBody className="Account__modal__body">

          <div>
            Are you sure you want to cancel your account?
            </div>
          <br />
          <div>
            It will make it a lot tougher to achieve your goals and we will miss you.
            </div>
        </ModalBody>
        <ModalFooter style={{ flexWrap: 'nowrap' }}>
          <div style={{ width: '100%' }} />
          <Button
            style={{ marginRight: '20px', height: '48px' }}
            small
            onClick={() => {
              this.setState({ modalCancelSub: false });
              sendCancelSubscriptionRequest(currentSubscription);
            }}
            block
          >
            Yes
            </Button>
          <Button style={{ height: '48px' }} block outline small onClick={() => this.setState({ modalCancelSub: false })}>No</Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderPaymentSelector = () => {
    const { plans } = this.props;
    const { planIndex } = this.state;
    if (!Array.isArray(plans)) {
      return null;
    }

    return (
      <div className="row">
        {plans.map((item, index) => {
          const active = index === planIndex;
          const btnText = active ? 'Current Plan' : 'Select Plan';
          const {
            name, description, id, price, period, period_unit,
          } = item;
          const activeClass = active ? 'active' : '';
          const priceText = `per ${period > 1 ? period : ''} ${period_unit}`;
          const planPriceName = `${name} ${formatMoneyString(price / 100)}`;
          return (
            <div key={id} className="col-sm-12 mb-md-0 mb-3 col-lg-4 pb-4">
              <div
                className={`Account__plan ${activeClass}`}
                key={index}
                onClick={() => this.onIndexChanged(index)}
              >
                <div className="col justify-content-center no-gutters">
                  <h1 className={activeClass}>{planPriceName}</h1>
                  <h2 className={activeClass}>{priceText}</h2>
                  <h2 className={activeClass}>{description}</h2>
                </div>

                <Button type="button" className={activeClass}>{btnText}</Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderPaymentForm = () => {
    const {
      plans, error, user, isProcessingPayment,
    } = this.props;
    const { paymentFormVisible } = this.state;
    if (!Array.isArray(plans) || plans.length === 0) {
      return null;
    }


    if (!paymentFormVisible && !error && !isProcessingPayment) {
      return (
        <div className="Account__info">
          <p>Your credit card is safely stored for this account subscription</p>
          <button type="button" onClick={this.forceShowUpdatePayment}>Update Payment Information</button>
        </div>
      );
    }

    return (
      <div className="Account__form">
        <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
          <h2>Change & Update Billing Info</h2>
          <PaymentForm
            onCheckout={this.onCheckout}
            buttonText="Update Payment Info"
            isProcessing={isProcessingPayment}
            paymentError={error}
            user={user}
          />
        </div>
      </div>
    );
  };


  renderInvoices = () => {
    const {
      invoices, isFetching, invoiceDownloadState, downloadInvoice,
    } = this.props;
    if (!invoices || isFetching) {
      return (
        <Spinner isLoading />
      );
    }
    return (
      <div className="Account__table">
        <Table>
          <thead>
            <tr className="table-header">
              {Constants.TABLE_HEADERS.map(item => (
                <th key={item}>{item}</th>
              ))}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {invoices.map((item, index) => {
              const {
                id, total, date, line_items,
              } = item;

              const plan = Array.isArray(line_items) && line_items.length ? line_items[0] : null;
              const planType = plan ? plan.description : '';
              const downloading = invoiceDownloadState['id'] === id && invoiceDownloadState.isDownloading;

              const dateString = moment.unix(date).format('MM/DD/YYYY');
              const className = classnames({
                'fa fa-refresh fa-spin': downloading,
                'fa fa-eye': !downloading,
              });
              return (
                <tr key={index}>
                  <th scope="row">{id}</th>
                  <td>{planType}</td>
                  <td>{formatMoneyString(total / 100)}</td>
                  <td>{dateString}</td>
                  <td className="Account__invoice-download" onClick={() => downloadInvoice(id)}>
                    <i className={className} aria-hidden="true" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  renderSubAlert = () => {
    const { currentSubscription, isCheckingSub } = this.props;
    const isValid = isValidSubscription(currentSubscription);
    const canceledAt = getCanceledTime(currentSubscription);
    const isCanceled = canceledAt !== null;

    const className = classnames('Account__alert-sub', {
      hide: !isCanceled && (isValid || isCheckingSub),
    });

    const title = isCanceled ? `Your subscription will be canceled at ${moment.unix(canceledAt).format('MMM DD, YYYY')}` : 'Your subscription has expired.';
    const subTitle = isCanceled ? '' : 'Please select a plan to start achiving your goals.';
    return (
      <Alert className={className} color="success">
        <p>{title}</p>
        <p className="mb-0">{subTitle}</p>
      </Alert>
    );
  };

  renderCancelSub = () => {
    const { currentSubscription, isCancellingSub } = this.props;
    const isValidSub = isValidSubscription(currentSubscription);
    const canceledAt = getCanceledTime(currentSubscription);
    const isCanceled = canceledAt !== null;
    if (isValidSub && !isCanceled) {
      return (
        <div className="Account__cancel">
          <button type="button" disabled={isCancellingSub} onClick={() => this.setState({ modalCancelSub: true })}>
            Cancel Account
              </button>
        </div>
      );
    }

    return null;
  };


  renderBody() {
    const { isChangingPlan, appleSubsiptions, isFetching } = this.props;
    const { activeTab } = this.state;
    const loadingClassName = classnames('Account__loading', {
      hide: !isChangingPlan && !isFetching,
    });

    if (Array.isArray(appleSubsiptions) && appleSubsiptions.length) {
      return (
        <div className="Account__alert-container">
          <Alert className="Account__alert-apple-sub" color="success">
            <p>Thank you for joining The FITT Cycle. You're account and subscription information can be viewed and updated in the Apple iOS App.</p>
          </Alert>
        </div>
      );
    }

    return (
      <>
        <div className="container">
          {this.renderSubAlert()}
          <div className="d-flex flex-column justify-content-between align-items-center flex-md-row Account__top">
            <h1>Your Account</h1>
            <div className="d-flex flex-row align-items-center mt-2 mt-lg-0">
              {Constants.TABLE_TABS.map((item, index) => {
                const { title, tab } = item;
                const active = tab === activeTab;
                const activeClass = active ? 'active' : '';
                return (
                  <Button
                    className={`Account__top__button ${activeClass}`}
                    key={index}
                    onClick={() => this.onActiveTabChange(tab)}
                    type="button"
                  >
                    {title}
                  </Button>
                );
              })}
            </div>
          </div>
          <div style={{ minHeight: '60vh' }}>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <>
                  {this.renderPaymentSelector()}
                  {this.renderPaymentForm()}
                </>
              </TabPane>
              <TabPane tabId="2">{this.renderInvoices()}</TabPane>

            </TabContent>
          </div>
          {this.renderCancelSub()}
        </div>

        {this.renderAlertModal()}
        {this.renderAlertCancelSubModal()}
        {<div className={loadingClassName}><Spinner isLoading /></div>}
      </>
    );
  }

  render() {
    const { subscriptionStatus } = this.state;
     if (subscriptionStatus === 'active') {
      return (
        <div className="Account">
          <Header className="Account__header" style={{ backgroundColor: 'white' }} RightComponent={this.renderMenu} />
          {this.renderBody()}
        </div>
      );
     }
       return (<PaymentPlanPage {...this.props} />);
  }
}

const key = 'yourAccount';

const mapStateToProps = createStructuredSelector({
  plans: getPlanSelector,
  appleSubsiptions: getAppSubscriptionSelector,
  isFetching: YourAccountSelector.getFetchingSelector,
  invoices: YourAccountSelector.getInvoicesSelector,
  user: getUserSelector,
  isProcessingPayment: YourAccountSelector.getProcessingPaymentSelector,
  error: YourAccountSelector.getErrorSelector,
  isCheckingSub: YourAccountSelector.getIsCheckingSubSelector,
  isCancellingSub: YourAccountSelector.getIsCancellingSubSelector,
  invoiceDownloadState: YourAccountSelector.getInvoiceDownloadStateSelector,
  currentSubscription: YourAccountSelector.getCurrentSubscriptionSelector,
  isChangingPlan: YourAccountSelector.getIsChangingPlanSelector,

});

const mapDispatchToProps = dispatch => ({
  getInvoices: evt => dispatch(YourAccountActions.getInvoicesRequest(evt)),
  getSubscriptions: evt => dispatch(YourAccountActions.getSubscriptionRequest(evt)),
  logout: evt => dispatch(logoutRequest(evt)),
  sendCheckoutRequest: evt => dispatch(YourAccountActions.checkoutRequest(evt)),
  sendCheckoutFailure: evt => dispatch(YourAccountActions.checkoutFailure(evt)),
  sendCheckoutSuccess: evt => dispatch(YourAccountActions.checkoutSuccess(evt)),
  sendChangePlanSuccess: evt => dispatch(YourAccountActions.changePlanSuccess(evt)),
  sendChangePlanRequest: evt => dispatch(YourAccountActions.changePlanRequest(evt)),
  sendCancelSubscriptionRequest: evt => dispatch(YourAccountActions.cancelSubscriptionRequest(evt)),
  downloadInvoice: evt => dispatch(YourAccountActions.downloadInvoiceRequest(evt)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({ key, saga });

const withReducer = injectReducer({ key, reducer });

export default compose(
  withSaga,
  withConnect,
  withReducer,
)(YourAccountPage);
