import Toast from 'Utils/Toast';
import Api from 'Services/Api/Api';
import ApiInstance from 'Services/Api/ApiInstance';
import {
  put, takeLatest, call, take,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { loginFailure, loginSuccess } from 'Store/Auth/AuthActions';
import { LOGIN_REQUEST } from 'Store/Auth/AuthActionTypes';
import { getPlanRequest } from 'Store/Plan/PlanActions';
import { appFetchingStart, appFetchingEnd } from 'Store/AppFetching/AppFetchingActions';
import { GET_PLANS_SUCCESS } from 'Store/Plan/PlanActionTypes';


function* loginSaga(action) {
  try {
    yield put(appFetchingStart());
    const response = yield call(Api.login, { auth: action.payload });
    const { user, meta } = response;

    ApiInstance.defaults.headers.common.Authorization = `Bearer ${meta.jwt}`;

    yield put(getPlanRequest());
    yield put(appFetchingEnd());


    yield take(GET_PLANS_SUCCESS);

    yield put(loginSuccess({ user, token: meta.jwt }));
    yield put(push('/'));
  } catch (error) {
    Toast.error(error);
    yield put(appFetchingEnd());
    yield put(loginFailure(error.message));
  }
}

export default function* loginWatcher() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
}
