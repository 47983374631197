import React from 'react';
import Lottie from 'react-lottie';
import Assets from 'Assets';

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  isLoading: Boolean
}

const Spinner: React.SFC<SpinnerProps> = props => {
  const { isLoading, ...rest } = props;
  if (!isLoading) return null;
  return (
    <div
      style={{
        height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      {...rest}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: Assets.animation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={120}
        width={120}
      />
    </div>
  );
};

export default Spinner;
