import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'redux-injectors';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import Header from 'Components/Header/Header';
import Button from 'Components/Button/Button';
import Input from 'Components/Input/Input';
import Constants from 'Utils/Constants';
import { getAppFetchingSelector } from 'Store/AppFetching/AppFetchingSelector';
import { resetPasswordRequest } from 'Store/User/UserActions';
import saga from './ForgotPasswordSaga';
import './ForgotPasswordStyles.scss';


const key = 'forgotPassword';

export default props => {
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const isFetching = useSelector(getAppFetchingSelector);

  const onSubmit = values => {
    const { email } = values;
    const payload = {
      email,
      callback_url: Constants.UPDATE_PASSWORD_CALLBACK_URL,
    };
    dispatch(resetPasswordRequest(payload));
  };
  const { location } = props;
  let email = '';
  if (location && location.search) {
    const paramsUrl = new URLSearchParams(location.search);
    email = paramsUrl.get('email');
  }

  const renderForm = () => (
    <Formik
      initialValues={{ email }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
      })}
      render={props => {
        const {
          handleChange,
          handleBlur,
          values,
          errors,
          handleSubmit,
        } = props;
        const disabled = !isEmpty(errors);
        return (
          <form onSubmit={handleSubmit}>
            <Input
              className="ForgotPassword__input"
              name="email"
              type="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email}
            />

            <Button
              type="submit"
              loading={isFetching}
              disabled={disabled}
            >
              Send Password
            </Button>
          </form>
        );
      }}
    />
  );

  const renderRightComponent = () => (
    <div className="row align-items-center ">
      <Link className="link" to="/login"><Button className="ForgotPassword__login">Login</Button></Link>
      <Link className="link" to="/signUp"><Button className="ForgotPassword__signup">Sign Up</Button></Link>
    </div>
  );


  return (
    <div className="ForgotPassword">
      <Header RightComponent={renderRightComponent} />
      <div className="container">
        <div className="col-lg-6 col-sm-12 mx-auto">
          <h1>Forgot Password</h1>
          <h2>We will email you a link to reset your password</h2>
          {renderForm()}
        </div>
      </div>
    </div>
  );
};
