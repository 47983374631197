import { createSelector } from 'reselect';
import { initialState } from './YourAccountInitialState';

const yourAccountSelector = state => state.yourAccount || initialState;

export const getFetchingSelector = createSelector(
  yourAccountSelector,
  yourAccount => yourAccount.isFetching,
);

export const getProcessingPaymentSelector = createSelector(
  yourAccountSelector,
  yourAccount => yourAccount.isProcessingPayment,
);

export const getInvoicesSelector = createSelector(
  yourAccountSelector,
  yourAccount => yourAccount.invoices,
);

export const getErrorSelector = createSelector(
  yourAccountSelector,
  yourAccount => yourAccount.error,
);

export const getIsCancellingSubSelector = createSelector(
  yourAccountSelector,
  yourAccount => yourAccount.isCancellingSub,
);

export const getIsCheckingSubSelector = createSelector(
  yourAccountSelector,
  yourAccount => yourAccount.isCheckingSub,
);

export const getInvoiceDownloadStateSelector = createSelector(
  yourAccountSelector,
  yourAccount => yourAccount.invoiceDownloadState,
);

export const getCurrentSubscriptionSelector = createSelector(
  yourAccountSelector,
  yourAccount => yourAccount.currentSubscription,
);

export const getIsChangingPlanSelector = createSelector(
  yourAccountSelector,
  yourAccount => yourAccount.isChangingPlan,
);


export default {
  getFetchingSelector,
  getProcessingPaymentSelector,
  getInvoicesSelector,
  getErrorSelector,
  getIsCancellingSubSelector,
  getIsCheckingSubSelector,
  getInvoiceDownloadStateSelector,
  getCurrentSubscriptionSelector,
  getIsChangingPlanSelector,
};
