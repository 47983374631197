import { createAction } from 'redux-actions';
import AppleActionTypes from './AppleSubscriptionActionTypes';

export const getAppleSubscriptionRequest = createAction(AppleActionTypes.GET_APP_SUBSCRIPTION_REQUEST);
export const getAppleSubscriptionSuccess = createAction(AppleActionTypes.GET_APP_SUBSCRIPTION_SUCCESS);
export const getAppleSubscriptionFailure = createAction(AppleActionTypes.GET_APP_SUBSCRIPTION_FAILURE);

export default {
  getAppleSubscriptionRequest,
  getAppleSubscriptionSuccess,
  getAppleSubscriptionFailure,
};
