import createReducer from 'Utils/CreateReducer';
import LeadActionTypes from './LeadActionTypes';

function createLeadSuccess(state, action) {
  state = action.payload;
  return state;
}

// eslint-disable-next-line no-unused-vars
function createLeadRequest(state, action) {
  state = null;
  return state;
}

const leadReducer = createReducer(null, {
  [LeadActionTypes.CREATE_LEAD_SUCCESS]: createLeadSuccess,
  [LeadActionTypes.CREATE_LEAD_REQUEST]: createLeadRequest,
  [LeadActionTypes.CREATE_LEAD_FAILURE]: createLeadRequest,
  [LeadActionTypes.TRACKING_SALE_SUCCESS]: createLeadRequest,
  [LeadActionTypes.TRACKING_SALE_FAILURE]: createLeadRequest,
});

export default leadReducer;
