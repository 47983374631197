import React from 'react';
import { useSelector } from 'react-redux';
import { getAppLoadingSelector } from 'Store/AppLoading/AppLoadingSelector';
import './PageLoaderStyles.scss';

export default () => {
  const isAppLoading = useSelector(getAppLoadingSelector);
  return (
    <>
      <div className={`start ${isAppLoading ? 'is-active' : ''}`} />
      <div className={`infraloader ${isAppLoading ? 'is-active' : ''}`} />
    </>
  );
};
