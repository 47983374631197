import React from 'react';
import Assets from 'Assets';
import { Link } from 'react-router-dom';
import './HeaderStyles.scss';

const Header = ({
  RightComponent = <Link to="/login" className="link">Login</Link>,
  ...rest
}) => (
  <header id="header" {...rest}>
    <Link to="/signup" className="link">
      <img style={{ backgroundColor: 'transparent' }} src={Assets.images.logoHeader} alt="logo" />
    </Link>
    <div>
      {typeof RightComponent === 'function' ? RightComponent() : RightComponent}
    </div>
  </header>
);

export default Header;
