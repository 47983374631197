import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SharePostStyles.scss';
import Assets from 'Assets';
import Header from 'Components/Header/Header';
import Button from 'Components/Button/Button';
import { DOMAIN_URL } from 'Utils/Constants';

export default props => {
  const { location } = props;
  const state = location.state ? location.state : {};
  const {
    owner_name = 'Someone', owner_photo, post_photo = Assets.images.post_default,
    post_owner_name = 'Someone', post_description = '',
    post_likes_count = 0, post_comment_count = 0,
    promotion_referral_link = `${DOMAIN_URL}/signup`,
  } = state;

  const [isValidAvatar, setValidAvatar] = useState(typeof owner_photo === 'string'
        && owner_photo.length);

  const renderRightComponent = () => (
        <div className="row align-items-center ">
            <Link className="link" to="/login"><Button className="UpdatePassword__login">Login</Button></Link>
            <Link className="link d-none d-sm-block" to="/signUp"><Button className="UpdatePassword__signup ">Sign Up</Button></Link>
        </div>
  );

  return (
    <>
            <Header RightComponent={renderRightComponent} />
            <div className="SharePostPage">
                <div className="container">
                    <div className="SharePostPage__card col-sm-8 col-md-5 mx-auto">
                        <div className="SharePostPage__header">
                            {isValidAvatar ? (
                                <img
                                  src={owner_photo}
                                  className="avatar"
                                  alt="user_image"
                                  onError={() => setValidAvatar(false)}
                                />
                            ) : (
                                    <div className="userMenu__avatar center">
                                        <i className="fa fa-user" aria-hidden="true" />
                                    </div>
                            )}
                            <div className="ml-3">
                                <p className="owner">{`@${owner_name}`}</p>
                                <p className="description">Shared this recipe with you!</p>
                            </div>
                        </div>

                        <div className="img-container">
                            <img width="100%" src={post_photo} className="img-fluid d-block mx-auto" alt="Recipe" />
                            <div className="overlay-bottom">
                                <p className="owner">{`@${post_owner_name}`}</p>
                                <p className="description">{post_description}</p>
                            </div>

                            <div className="overlay-right">
                                <div>
                                    <img width="32px" src={Assets.images.like} />
                                    <span>{post_likes_count}</span>
                                </div>
                                <div>
                                    <img width="32px" src={Assets.images.comment} />
                                    <span>{post_comment_count}</span>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <p className="col-sm-8 col-md-5 mx-auto text-description">Please login in or create an account to start achieving your goals!</p>
            </div>
            <div className="container">
                <div className="col-sm-8 col-md-5 mx-auto SharePostPage__bottom">
                    <Link to="/login" className="button-grey"><div>Log In</div></Link>
                    <div className="d-flex flex-row justify-content-between align-items-center my-4">
                        <div className="horizontal-line" />
                        <span>or</span>
                        <div className="horizontal-line" />
                    </div>
                    <button disabled={promotion_referral_link === ''} type="button" className="button-primary" onClick={() => { window.location.href = promotion_referral_link; }}>Create New Account</button>
                </div>
            </div>
    </>

  );
};
