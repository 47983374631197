import moment from 'moment-timezone';
import Toast from 'Utils/Toast';
import Api from 'Services/Api/Api';
import FirstPromoter from 'Services/FirstPromoter/FirstPromoter';
import ApiInstance from 'Services/Api/ApiInstance';
import { put, takeLatest, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { UserTokenModel } from 'Types';
import { signUpSuccess, signUpFailure } from 'Store/Auth/AuthActions';
import { SIGNUP_REQUEST } from 'Store/Auth/AuthActionTypes';
import { getPlanRequest } from 'Store/Plan/PlanActions';
import { createLeadSuccess, createLeadFailure } from 'Store/Lead/LeadActions';
import { CREATE_LEAD_REQUEST } from 'Store/Lead/LeadActionTypes';
import { appFetchingStart, appFetchingEnd } from 'Store/AppFetching/AppFetchingActions';
import CustomerIO from 'Services/CustomerIO/CustomerIO';
import { reportCrash } from 'Utils/Utils';


function* signupSaga(action) {
  try {
    yield put(appFetchingStart());
    const timezone = moment.tz.guess();
    const payload = { ...action.payload, timezone };
    const response: UserTokenModel = yield call(Api.signup, { user: payload });
    const { user, meta } = response;

    ApiInstance.defaults.headers.common.Authorization = `Bearer ${meta.jwt}`;

    CustomerIO.createCustomer(user);

    yield put(signUpSuccess({ user, token: meta.jwt }));
    yield put(getPlanRequest());

    yield put(appFetchingEnd());
    yield put(push('/signup/1'));
  } catch (error) {
    if (error && error.code === 403) {
      const { email } = action.payload;
      window.location.href = `/login?email=${email}`;
    } else {
      Toast.error(error);
    }
    yield put(appFetchingEnd());
    yield put(signUpFailure(error.message));
  }
}

function* createLeadSaga(action) {
  try {
    const { email, ref_id, first_name } = action.payload;
    if (!ref_id) {
      yield put(createLeadSuccess(null));
      return;
    }

    const lead = yield call(FirstPromoter.createLead, {
      email, ref_id, state: 'signup', username: first_name,
    });

    yield call(FirstPromoter.trackingLeadSignUp, { email, ref_id });
    yield put(createLeadSuccess(lead));
  } catch (error) {
    yield put(createLeadFailure(error.message));
    reportCrash(error);
  }
}

export default function* signupWatcher() {
  yield takeLatest(SIGNUP_REQUEST, signupSaga);
  yield takeLatest(CREATE_LEAD_REQUEST, createLeadSaga);
}
