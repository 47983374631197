import Constants from 'Utils/Constants';
import FirstPromoterInstance from './FirstPromoterInstance';


export const trackingLeadSignUp = payload => FirstPromoterInstance.post('/track/signup', Object.assign(payload, { wid: Constants.FIRST_PROMOTER_WID }));
export const trackingSale = payload => FirstPromoterInstance.post('/track/sale', Object.assign(payload, { wid: Constants.FIRST_PROMOTER_WID }));
export const createLead = payload => FirstPromoterInstance.post('/leads/create', payload);
export const updateLead = payload => FirstPromoterInstance.put('/leads/update', payload);

export default {
  trackingLeadSignUp, trackingSale, createLead, updateLead,
};
