import { put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { appFetchingEnd, appFetchingStart } from 'Store/AppFetching/AppFetchingActions';
import { UPDATE_WEIGHT_REQUEST } from 'Store/User/UserActionTypes';


function* updateWeightSaga() {
  yield put(appFetchingStart());
  yield delay(1000);
  yield put(appFetchingEnd());
  yield put(push('/signup/5'));
}


export default function* signupWatcher() {
  yield takeLatest(UPDATE_WEIGHT_REQUEST, updateWeightSaga);
}
