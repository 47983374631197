
import createReducer from 'Utils/CreateReducer';
import { getValidSubscription } from 'Utils/Utils';
import YourAccountActionTypes from './YourAccountActionTypes';
import { initialState } from './YourAccountInitialState';


// eslint-disable-next-line no-unused-vars
function getInvoiceRequest(state, action) {
  state.error = null;
  state.isFetching = true;
  return state;
}

function getInvoiceSuccess(state, action) {
  state.isFetching = false;
  state.invoices = action.payload;
  return state;
}

// eslint-disable-next-line no-unused-vars
function getInvoiceFailure(state, action) {
  state.isFetching = false;
  state.error = action.payload;
  return state;
}

// eslint-disable-next-line no-unused-vars
function checkoutRequest(state, action) {
  state.error = null;
  state.isProcessingPayment = true;
  return state;
}

function checkoutSuccess(state, action) {
  state.isProcessingPayment = false;
  state.invoices = action.payload;
  state.error = null;
  return state;
}

// eslint-disable-next-line no-unused-vars
function checkoutFailure(state, action) {
  state.isProcessingPayment = false;
  state.error = action.payload;
  return state;
}

// eslint-disable-next-line no-unused-vars
function cancelSubscriptionRequest(state, action) {
  state.isCancellingSub = true;
  return state;
}


// eslint-disable-next-line no-unused-vars
function cancelSubscriptionSuccess(state, action) {
  state.isCancellingSub = false;
  state.currentSubscription = action.payload;
  return state;
}


// eslint-disable-next-line no-unused-vars
function cancelSubscriptionFailure(state, action) {
  state.isCancellingSub = false;
  return state;
}

// eslint-disable-next-line no-unused-vars
function getSubscriptionRequest(state, action) {
  state.isCheckingSub = false;
  return state;
}

function getSubscriptionSuccess(state, action) {
  state.isCheckingSub = false;
  state.subscriptions = action.payload;
  const sub = getValidSubscription(action.payload);
  state.currentSubscription = sub;

  return state;
}


// eslint-disable-next-line no-unused-vars
function getSubscriptionFailure(state, action) {
  state.isCheckingSub = false;
  state.subscriptions = [];
  state.currentSubscription = null;
  return state;
}

// eslint-disable-next-line no-unused-vars
function downloadInvoiceRequest(state, action) {
  state.invoiceDownloadState = {
    id: action.payload,
    isDownloading: true,
  };

  return state;
}

// eslint-disable-next-line no-unused-vars
function downloadInvoiceSuccess(state, action) {
  state.invoiceDownloadState = {
    id: action.payload,
    isDownloading: false,
  };
  return state;
}


// eslint-disable-next-line no-unused-vars
function downloadInvoiceFailure(state, action) {
  state.invoiceDownloadState = {
    id: action.payload,
    isDownloading: false,
  };
  return state;
}

// eslint-disable-next-line no-unused-vars
function changePlanRequest(state, action) {
  state.isChangingPlan = true;
  return state;
}

// eslint-disable-next-line no-unused-vars
function changePlanSuccess(state, action) {
  state.isChangingPlan = false;
  state.currentSubscription = action.payload;
  return state;
}


// eslint-disable-next-line no-unused-vars
function changePlanFailure(state, action) {
  state.isChangingPlan = false;
  return state;
}


const yourAccountReducer = createReducer(initialState, {
  [YourAccountActionTypes.GET_INVOICES_REQUEST]: getInvoiceRequest,
  [YourAccountActionTypes.GET_INVOICES_SUCCESS]: getInvoiceSuccess,
  [YourAccountActionTypes.GET_INVOICES_FAILURE]: getInvoiceFailure,
  [YourAccountActionTypes.CHECKOUT_REQUEST]: checkoutRequest,
  [YourAccountActionTypes.CHECKOUT_SUCCESS]: checkoutSuccess,
  [YourAccountActionTypes.CHECKOUT_FAILURE]: checkoutFailure,
  [YourAccountActionTypes.CANCEL_SUBSCRIPTION_REQUEST]: cancelSubscriptionRequest,
  [YourAccountActionTypes.CANCEL_SUBSCRIPTION_SUCCESS]: cancelSubscriptionSuccess,
  [YourAccountActionTypes.CANCEL_SUBSCRIPTION_FAILURE]: cancelSubscriptionFailure,
  [YourAccountActionTypes.GET_SUBSCRIPTION_SUCCESS]: getSubscriptionSuccess,
  [YourAccountActionTypes.GET_SUBSCRIPTION_FAILURE]: getSubscriptionFailure,
  [YourAccountActionTypes.DOWNLOAD_INVOICE_REQUEST]: downloadInvoiceRequest,
  [YourAccountActionTypes.DOWNLOAD_INVOICE_SUCCESS]: downloadInvoiceSuccess,
  [YourAccountActionTypes.DOWNLOAD_INVOICE_FAILURE]: downloadInvoiceFailure,
  [YourAccountActionTypes.CHANGE_PLAN_REQUEST]: changePlanRequest,
  [YourAccountActionTypes.CHANGE_PLAN_SUCCESS]: changePlanSuccess,
  [YourAccountActionTypes.CHANGE_PLAN_FAILURE]: changePlanFailure,
});
export default yourAccountReducer;
