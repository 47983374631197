import { createSelector } from 'reselect';

const appFetchingSelector = state => state.isFetching;


export const getAppFetchingSelector = createSelector(
  appFetchingSelector,
  appFetching => appFetching,
);

export default {
  getAppFetchingSelector,
};
