import { createAction } from 'redux-actions';
import PlanActionTypes from './PlanActionTypes';

export const getPlanRequest = createAction(PlanActionTypes.GET_PLANS_REQUEST);
export const getPlanSuccess = createAction(PlanActionTypes.GET_PLANS_SUCCESS);
export const getPlanFailure = createAction(PlanActionTypes.GET_PLANS_FAILURE);

export default {
  getPlanRequest,
  getPlanSuccess,
  getPlanFailure,
};
