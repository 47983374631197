import Toast from 'Utils/Toast';
import Api from 'Services/Api/Api';
import { put, takeLatest, call } from 'redux-saga/effects';
import { updatePasswordSuccess, updatePasswordFailure } from 'Store/User/UserActions';
import { UPDATE_PASSWORD_REQUEST } from 'Store/User/UserActionTypes';
import { appFetchingStart, appFetchingEnd } from 'Store/AppFetching/AppFetchingActions';


function* updatePasswordSaga(action) {
  try {
    const { callback, ...rest } = action.payload;
    yield put(appFetchingStart());
    yield call(Api.updatePassword, rest);
    yield put(updatePasswordSuccess());
    if (typeof callback === 'function') {
      callback();
    }
    yield put(appFetchingEnd());
  } catch (error) {
    Toast.error(error);
    yield put(updatePasswordFailure(error.message));
    yield put(appFetchingEnd());
  }
}


export default function* updateWatcher() {
  yield takeLatest(UPDATE_PASSWORD_REQUEST, updatePasswordSaga);
}
