import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTokenSelector } from 'Store/Auth/AuthSelector';

const PrivateRoute = ({
  component: Component, deeplink, token, ...rest
}) => (
  <Route
    {...rest}
    render={props => (token ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: deeplink ? deeplink.pathname : '/signup',
          state: deeplink ? deeplink.state : { from: props.location },
        }}
      />
    ))}
  />
);
const mapStateToProps = createStructuredSelector({
  token: getTokenSelector,
});

export default connect(mapStateToProps)(PrivateRoute);
