// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import Constants from 'Utils/Constants';
import Assets from 'Assets';
import Button from 'Components/Button/Button';
import './FooterStyles.scss';
import { createMailtoLink } from 'Utils/Utils';

const Footer = () => {
  const onContact = () => {
    window.location.href = createMailtoLink(Constants.CONTACT_MAIL);
  };
  return (
    <div id="footer">
      <div className="container">
        <div className="d-flex flex-md-row justify-content-between align-items-center flex-column">
          <div className="d-flex flex-row align-items-center">
            <Link to="/signup">
              <img alt="The Fitt Cycle" src={Assets.images.logoFooter} />
            </Link>
            <div className="row socialIcon">
              {Constants.SOCIAL_ICONS.map((item, index) => {
                const { to, icon } = item;
                return (
                  <a key={index} href={to} target="_blank">
                    <i className={icon} />
                  </a>
                );
              })}
            </div>
          </div>
          <div className="topRight mt-md-0 mt-4">
            <span>Get in touch —</span>
            <Button onClick={onContact} type="button" className="button button-secondary">
              Contact us
            </Button>
          </div>
        </div>
        <div className="row justify-content-between align-items-center bottom no-gutters px-md-0 px-2">
          <div className="d-flex flex-row">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/term">Terms & Conditions</Link>
            <Link to="/disclaimer">Disclaimer</Link>
            <a href="https://help.thefittcycle.com/">Support</a>
          </div>
          <div className="d-flex flex-row">
            <span>{`${Constants.VERSION} `}</span>
            <span>© 2019. All rights reserved</span>
          </div>

        </div>
      </div>

    </div>
  );
};
export default Footer;
