import React, { useState } from 'react';
import Button from 'Components/Button/Button';
import Header from 'Components/Header/Header';
import ButtonLogout from 'Components/Button/ButtonLogout';
import constants from 'Utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'redux-injectors';
import { getAppFetchingSelector } from 'Store/AppFetching/AppFetchingSelector';
import { getUserSelector } from 'Store/User/UserSelector';
import { updateGoalRequest } from 'Store/User/UserActions';
import saga from './SignUp5Saga';
import './SignUp5Styles.scss';

const key = 'signup5';

export default () => {
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const isFetching = useSelector(getAppFetchingSelector);
  const user = useSelector(getUserSelector);
  const defaultGoal = user && typeof user.goal === 'string' && user.goal !== '' ? user.goal : constants.GOALS[0].key;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [goal, setGoal] = useState(defaultGoal);

  const onContinue = event => {
    event.preventDefault();
    dispatch(updateGoalRequest(goal));
  };

  const onIndexChanged = (index, goal) => {
    if (currentIndex === index) return;
    setCurrentIndex(index);
    setGoal(goal);
  };


  return (
    <div className="SignUp5">
      <Header RightComponent={<ButtonLogout />} />
      <div className="container">
        <div className="col-lg-4 col-md-8 col-sm-12 p-sm-2 align-self-center mx-auto">
          <h6>Step 5 of 6</h6>
          <h1>What's your goal?</h1>
          <div className="row no-gutters align-items-end SignUp5__control">
            {constants.GOALS.map((item, index) => {
              const { title, subTitle, key } = item;
              const active = index === currentIndex;
              const activeClass = active ? 'active' : '';
              return (
                <div
                  key={index}
                  className={`SignUp5__selector ${activeClass}`}
                  onClick={() => onIndexChanged(index, key)}
                >
                  <div className="row align-items-center">
                    <div className={`checkbox ${activeClass}`}>
                      <div />
                    </div>
                    <h6>{title}</h6>
                  </div>
                  <span>{subTitle}</span>
                </div>
              );
            })}
          </div>
          <Button
            type="button"
            loading={isFetching}
            onClick={onContinue}
          >
            Continue
          </Button>
        </div>

      </div>
    </div>
  );
};
