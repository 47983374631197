import { createAction } from 'redux-actions';
import YourAccountActionTypes from './YourAccountActionTypes';


export const getInvoicesRequest = createAction(YourAccountActionTypes.GET_INVOICES_REQUEST);
export const getInvoicesSuccess = createAction(YourAccountActionTypes.GET_INVOICES_SUCCESS);
export const getInvoicesFailure = createAction(YourAccountActionTypes.GET_INVOICES_FAILURE);

export const getSubscriptionRequest = createAction(YourAccountActionTypes.GET_SUBSCRIPTION_REQUEST);
export const getSubscriptionSuccess = createAction(YourAccountActionTypes.GET_SUBSCRIPTION_SUCCESS);
export const getSubscriptionFailure = createAction(YourAccountActionTypes.GET_SUBSCRIPTION_FAILURE);

export const cancelSubscriptionRequest = createAction(YourAccountActionTypes.CANCEL_SUBSCRIPTION_REQUEST);
export const cancelSubscriptionSuccess = createAction(YourAccountActionTypes.CANCEL_SUBSCRIPTION_SUCCESS);
export const cancelSubscriptionFailure = createAction(YourAccountActionTypes.CANCEL_SUBSCRIPTION_FAILURE);

export const checkoutRequest = createAction(YourAccountActionTypes.CHECKOUT_REQUEST);
export const checkoutSuccess = createAction(YourAccountActionTypes.CHECKOUT_SUCCESS);
export const checkoutFailure = createAction(YourAccountActionTypes.CHECKOUT_FAILURE);

export const changePlanRequest = createAction(YourAccountActionTypes.CHANGE_PLAN_REQUEST);
export const changePlanSuccess = createAction(YourAccountActionTypes.CHANGE_PLAN_SUCCESS);
export const changePlanFailure = createAction(YourAccountActionTypes.CHANGE_PLAN_FAILURE);

export const downloadInvoiceRequest = createAction(YourAccountActionTypes.DOWNLOAD_INVOICE_REQUEST);
export const downloadInvoiceSuccess = createAction(YourAccountActionTypes.DOWNLOAD_INVOICE_SUCCESS);
export const downloadInvoiceFailure = createAction(YourAccountActionTypes.DOWNLOAD_INVOICE_FAILURE);

export default {
  getInvoicesRequest,
  getInvoicesSuccess,
  getInvoicesFailure,
  checkoutRequest,
  checkoutSuccess,
  checkoutFailure,
  getSubscriptionRequest,
  getSubscriptionSuccess,
  getSubscriptionFailure,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  downloadInvoiceRequest,
  downloadInvoiceSuccess,
  downloadInvoiceFailure,
  changePlanRequest,
  changePlanSuccess,
  changePlanFailure,
};
