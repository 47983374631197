import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ShareRecipeStyles.scss';
import Assets from 'Assets';
import Header from 'Components/Header/Header';
import Button from 'Components/Button/Button';
import { DOMAIN_URL } from 'Utils/Constants';


export default props => {
  const { location } = props;
  const state = location.state ? location.state : {};
  const {
    owner_name = 'Someone', owner_photo, meal_photo = Assets.images.recipe_default,
    meal_name = 'Favorite Quinoa Salad', meal_serving = '1 Serving',
    meal_fat = '0 g', meal_carb = '0 g', meal_protein = '0 g',
    promotion_referral_link = `${DOMAIN_URL}/signup`,
  } = state;


  const [isValidAvatar, setValidAvatar] = useState(typeof owner_photo === 'string'
        && owner_photo.length);

  const renderRightComponent = () => (
        <div className="row align-items-center justify-content-end">
            <Link className="link" to="/login"><Button className="UpdatePassword__login">Login</Button></Link>
            <Link className="link link d-none d-sm-block" to="/signUp"><Button className="UpdatePassword__signup">Sign Up</Button></Link>
        </div>
  );

  return (
    <>
            <Header RightComponent={renderRightComponent} />
            <div className="ShareRecipePage">
                <div className="container">
                    <div className="ShareRecipePage__card col-sm-8 col-md-5 mx-auto">
                        <div className="ShareRecipePage__header">
                            {isValidAvatar ? (
                                <img
                                  src={owner_photo}
                                  className="avatar"
                                  alt="user_image"
                                  onError={() => setValidAvatar(false)}
                                />
                            ) : (
                                    <div className="userMenu__avatar center">
                                        <i className="fa fa-user" aria-hidden="true" />
                                    </div>
                            )}
                            <div className="ml-3">
                                <p className="owner">{`@${owner_name}`}</p>
                                <p className="description">Shared this recipe with you!</p>
                            </div>
                        </div>

                        <img width="100%" src={meal_photo} className="img-fluid d-block mx-auto" alt="Recipe" />

                        <div className="d-flex flex-row align-items-center ShareRecipePage__meal">
                            <div>
                                <div className="name">{meal_name}</div>
                                <div style={{ marginTop: '6px' }} className="serving">{meal_serving}</div>
                            </div>
                        </div>

                        <div className="ShareRecipePage__macro d-flex flex-row align-items-center">
                            <div className="d-flex flex-column align-items-center">
                                <span className="value">{meal_carb}</span>
                                <span className="title">Carbs</span>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                                <span className="value">{meal_protein}</span>
                                <span className="title">Proteins</span>
                            </div>

                            <div className="d-flex flex-column align-items-center">
                                <span className="value">{meal_fat}</span>
                                <span className="title">Fat</span>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="col-sm-8 col-md-5 mx-auto text-description">Please login in or create an account to start achieving your goals!</p>
            </div>
            <div className="container">
                <div className="col-sm-8 col-md-5 mx-auto ShareRecipePage__bottom">
                    <Link to="/login" className="button-grey">Log In</Link>
                    <div className="d-flex flex-row justify-content-between align-items-center my-4">
                        <div className="horizontal-line" />
                        <span>or</span>
                        <div className="horizontal-line" />
                    </div>
                    <button disabled={promotion_referral_link === ''} type="button" className="button-primary" onClick={() => { window.location.href = promotion_referral_link; }}>Create New Account</button>
                </div>
            </div>
    </>

  );
};
