import { put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { UPDATE_HEIGHT_REQUEST } from 'Store/User/UserActionTypes';
import { appFetchingEnd, appFetchingStart } from 'Store/AppFetching/AppFetchingActions';

function* updateHeightSaga() {
  yield put(appFetchingStart());
  yield delay(1000);
  yield put(appFetchingEnd());
  yield put(push('/signup/4'));
}

export default function* signupWatcher() {
  yield takeLatest(UPDATE_HEIGHT_REQUEST, updateHeightSaga);
}
