import React, { useState } from 'react';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import Header from 'Components/Header/Header';
import Button from 'Components/Button/Button';
import Input from 'Components/Input/Input';
import Constants from 'Utils/Constants';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'redux-injectors';
import { parseUrl } from 'Utils/Utils';
import { getAppFetchingSelector } from 'Store/AppFetching/AppFetchingSelector';
import { updatePasswordRequest } from 'Store/User/UserActions';
import { logoutRequest } from 'Store/Auth/AuthActions';
import saga from './UpdatePasswordSaga';
import './UpdatePasswordStyles.scss';


const key = 'UpdatePassword';

function diff(ref, msg) {
  return this.test({
    name: 'diff',
    exclusive: false,
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || '${path} must be the diff as ${reference}',
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, 'diff', diff);

export default props => {
  useInjectSaga({ key, saga });

  const { location } = props;
  const dispatch = useDispatch();
  const isFetching = useSelector(getAppFetchingSelector);

  const [showPassword, setShowPassword] = useState(false);
  const [modal, setModal] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { reset_password_token, platform = 'web' } = parseUrl(location.search);

  const onSubmit = values => {
    const { password, confirmPassword: password_confirmation } = values;
    const payload = {
      reset_password_token,
      password,
      password_confirmation,
      callback: () => setModal(true),
    };
    dispatch(updatePasswordRequest(payload));
  };

  const renderForm = () => (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(6, 'Password must be has 6 characters at least.')
          .diff(Yup.ref('confirmPassword'), 'Password and confirm password does not match.')
          .required('Required'),
        confirmPassword: Yup.string()
          .min(6, 'Password must be has 6 characters at least.')
          .diff(Yup.ref('password'), 'Password and confirm password does not match.')
          .required('Required'),
      })}
      render={props => {
        const {
          handleChange,
          handleBlur,
          values,
          errors,
          handleSubmit,
        } = props;
        const disabled = !isEmpty(errors);
        const cn1 = showPassword ? 'fa fa-eye-slash' : 'fa fa-eye';
        const cn2 = showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye';
        return (
          <form onSubmit={handleSubmit}>
            <Input
              className="UpdatePassword__input"
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.password}
              RightComponent={<span onClick={() => setShowPassword(!showPassword)} className={cn1} />}
            />

            <Input
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.confirmPassword}
              RightComponent={<span onClick={() => setShowConfirmPassword(!showConfirmPassword)} className={cn2} />}
            />

            <Button
              className="UpdatePassword__button"
              type="submit"
              loading={isFetching}
              disabled={disabled}
            >
              Update Password
            </Button>
          </form>
        );
      }}
    />
  );

  const renderRightComponent = () => (
    <div className="row align-items-center ">
      <Link className="link" to="/login"><Button className="UpdatePassword__login">Login</Button></Link>
      <Link className="link" to="/signUp"><Button className="UpdatePassword__signup">Sign Up</Button></Link>
    </div>
  );

  const toggle = () => setModal(!modal);

  const returnLogin = () => {
    toggle();
    if (platform !== 'web') {
      window.location.href = Constants.MOBILE_DEEPLINK;
    } else {
      dispatch(logoutRequest());
      window.location.href = '/signup';
    }
  };

  const renderModal = () => (
    <Modal isOpen={modal} toggle={toggle} className="" centered>
      <ModalBody>
        <h1 className="UpdatePassword__modal-title">Password Updated</h1>
        <h3 className="UpdatePassword__modal-message">Your password has been updated successfully!.</h3>
      </ModalBody>
      <ModalFooter>
        <Button onClick={returnLogin}>{platform !== 'web' ? 'Return The Fitt Cycle App' : 'Return Login'}</Button>
      </ModalFooter>
    </Modal>
  );
  return (
    <div className="UpdatePassword">
      <Header RightComponent={renderRightComponent} />
      <div className="container">
        <div className="col-lg-6 col-sm-12 mx-auto">
          <h1>Update Password</h1>
          {renderForm()}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};
