import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutRequest } from 'Store/Auth/AuthActions';
import './ButtonLogoutStyles.scss';


const ButtonLogout = () => {
  const dispatch = useDispatch();
  return (
    <button className="ButtonLogout" type="button" onClick={() => dispatch(logoutRequest())}>
     Log Out
    </button>
  );
};

export default ButtonLogout;
