import Toast from 'Utils/Toast';
import Api from 'Services/Api/Api';
import { put, takeLatest, call } from 'redux-saga/effects';
import { resetPasswordSuccess, resetPasswordFailure } from 'Store/User/UserActions';
import { RESET_PASSWORD_REQUEST } from 'Store/User/UserActionTypes';
import { appFetchingStart, appFetchingEnd } from 'Store/AppFetching/AppFetchingActions';


function* forgotPasswordSaga(action) {
  try {
    yield put(appFetchingStart());
    yield call(Api.resetPassword, action.payload);

    yield put(resetPasswordSuccess());
    Toast.success('We sent an email to your inbox to reset your password');
    yield put(appFetchingEnd());
  } catch (error) {
    yield put(appFetchingEnd());
    Toast.error(error);
    yield put(resetPasswordFailure(error.message));
  }
}


export default function* forgotWatcher() {
  yield takeLatest(RESET_PASSWORD_REQUEST, forgotPasswordSaga);
}
