
import { put, takeLatest, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { appFetchingStart, appFetchingEnd } from 'Store/AppFetching/AppFetchingActions';
import { UPDATE_PROFILE_REQUEST } from 'Store/User/UserActionTypes';
import Api from 'Services/Api/Api';
import { reportCrash } from 'Utils/Utils';


function* updateProfileSaga(action) {
  try {
    yield put(appFetchingStart());
    yield call(Api.updateProfile, action.payload);
    yield put(appFetchingEnd());
    yield put(push('/payment/plan'));
  } catch (error) {
    yield put(appFetchingEnd());
    yield put(push('/payment/plan'));
    reportCrash(error);
  }
}


export default function* signupWatcher() {
  yield takeLatest(UPDATE_PROFILE_REQUEST, updateProfileSaga);
}
