import React from 'react';
import Header from 'Components/Header/Header';
import './NotFoundStyles.scss';

class NotFound extends React.PureComponent {
  render() {
    return (
      <div className="NotFound">
        <Header RightComponent={null} />
        <div className="h-75 d-flex justify-content-center align-items-center"><h1>Page Not Found</h1></div>
      </div>
    );
  }
}

export default NotFound;
