import { put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { appFetchingEnd, appFetchingStart } from 'Store/AppFetching/AppFetchingActions';
import { UPDATE_GOAL_REQUEST } from 'Store/User/UserActionTypes';


function* updateGoalSaga() {
  yield put(appFetchingStart());
  yield delay(1000);
  yield put(appFetchingEnd());
  yield put(push('/signup/6'));
}


export default function* signupWatcher() {
  yield takeLatest(UPDATE_GOAL_REQUEST, updateGoalSaga);
}
