const colors = {
  dark: '#232530',
  battleshipGrey: '#787b87',
  warmPink: '#ef5284',
  coolGreyTwo: '#b4b6bd',
  paleGrey: '#f9fafb',
  silver: '#d0d1d6',
  silverTwo: '#d0d1d6',
};

const images = {
  logoFooter: require('./Images/logoFooter.svg'),
  logoHeader: require('./Images/logoHeader.svg'),
  signUpBg: require('./Images/signUpBg.png'),
  download: require('./Images/download.png'),
  dummySlideImage: require('./Images/dummySlideImage.png'),
  paymentPlanBg: require('./Images/paymentPlanBg.png'),
  post_default: require('./Images/post_default.png'),
  workout_default: require('./Images/workout_default.png'),
  recipe_default: require('./Images/recipe_default.png'),
  like: require('./Images/like.svg'),
  comment: require('./Images/comment.svg'),
};

const testimonialImages = {
  AshleighK: require('./Images/Testimonial/AshleighK.jpg'),
  JenniferB: require('./Images/Testimonial/JenniferB.jpg'),
  JenniferW: require('./Images/Testimonial/JenniferW.jpg'),
  KyndalC: require('./Images/Testimonial/KyndalC.jpg'),
  LindsayW: require('./Images/Testimonial/LindsayW.jpg'),
  TiffanyP: require('./Images/Testimonial/TiffanyP.jpg'),
};

const animation = require('./Animations/loader.json');

const fonts = {
  regular: require('./Fonts/Averta-Regular.otf'),
  bold: require('./Fonts/Averta-Bold.otf'),
};
const Assets = {
  images,
  colors,
  animation,
  fonts,
  testimonialImages,
};

export default Assets;
