import { createSelector } from 'reselect';

const planSelector = state => state.plans;


export const getPlanSelector = createSelector(
  planSelector,
  plans => plans,
);

export default {
  getPlanSelector,
};
