import { createSelector } from 'reselect';

const appleSubscriptionSelector = state => state.appleSubscriptions;


export const getAppSubscriptionSelector = createSelector(
  appleSubscriptionSelector,
  appleSubscriptions => appleSubscriptions,
);

export default {
  getAppSubscriptionSelector,
};
