import { createAction } from 'redux-actions';
import AuthActionTypes from './AuthActionTypes';

export const loginRequest = createAction(AuthActionTypes.LOGIN_REQUEST);
export const loginSuccess = createAction(AuthActionTypes.LOGIN_SUCCESS);
export const loginFailure = createAction(AuthActionTypes.LOGIN_FAILURE);

export const signUpRequest = createAction(AuthActionTypes.SIGNUP_REQUEST);
export const signUpSuccess = createAction(AuthActionTypes.SIGNUP_SUCCESS);
export const signUpFailure = createAction(AuthActionTypes.SIGNUP_FAILURE);

export const logoutRequest = createAction(AuthActionTypes.LOGOUT_REQUEST);

export default {
  loginRequest,
  loginSuccess,
  loginFailure,

  signUpRequest,
  signUpSuccess,
  signUpFailure,

  logoutRequest,
};
