import createReducer from 'Utils/CreateReducer';
import AppleSubscriptionTypes from './AppleSubscriptionActionTypes';

function getAppleSubscriptionSuccess(state, action) {
  state = action.payload;
  return state;
}
const appleSubscriptionReducer = createReducer(null, {
  [AppleSubscriptionTypes.GET_APP_SUBSCRIPTION_SUCCESS]: getAppleSubscriptionSuccess,
});

export default appleSubscriptionReducer;
