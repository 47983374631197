import createReducer from 'Utils/CreateReducer';
import AuthActionTypes from 'Store/Auth/AuthActionTypes';
import UserActionTypes from './UserActionTypes';

function loginSuccess(state, action) {
  state = action.payload.user;
  return state;
}

function updateGenderRequest(state, action) {
  state.gender = action.payload;
  return state;
}

function updateBirthdayRequest(state, action) {
  state.birthday = action.payload;
  return state;
}
function updateHeightRequest(state, action) {
  state.height_ft = action.payload.height_ft;
  state.height_in = action.payload.height_in;
  return state;
}

function updateWeightRequest(state, action) {
  state.weight = action.payload;
  return state;
}

function updateGoalRequest(state, action) {
  state.goal = action.payload;
  return state;
}

function updateProfileRequest(state, action) {
  const user = action.payload;
  state = user;
  return state;
}

function updateProfileSuccess(state, action) {
  const user = action.payload;
  state = user;
  return state;
}

const userReducer = createReducer(null, {
  [AuthActionTypes.LOGIN_SUCCESS]: loginSuccess,
  [AuthActionTypes.SIGNUP_SUCCESS]: loginSuccess,
  [UserActionTypes.UPDATE_GENDER_REQUEST]: updateGenderRequest,
  [UserActionTypes.UPDATE_BIRTHDAY_REQUEST]: updateBirthdayRequest,
  [UserActionTypes.UPDATE_HEIGHT_REQUEST]: updateHeightRequest,
  [UserActionTypes.UPDATE_WEIGHT_REQUEST]: updateWeightRequest,
  [UserActionTypes.UPDATE_GOAL_REQUEST]: updateGoalRequest,
  [UserActionTypes.UPDATE_PROFILE_REQUEST]: updateProfileRequest,
  [UserActionTypes.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
});

export default userReducer;
