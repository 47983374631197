export const UPDATE_GENDER_REQUEST = 'USER/UPDATE_GENDER_REQUEST';

export const UPDATE_BIRTHDAY_REQUEST = 'USER/UPDATE_BIRTHDAY_REQUEST';

export const UPDATE_HEIGHT_REQUEST = 'USER/UPDATE_HEIGHT_REQUEST';

export const UPDATE_WEIGHT_REQUEST = 'USER/UPDATE_WEIGHT_REQUEST';

export const UPDATE_GOAL_REQUEST = 'USER/UPDATE_GOAL_REQUEST';

export const UPDATE_PROFILE_REQUEST = 'USER/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'USER/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'USER/UPDATE_PROFILE_FAILURE';

export const RESET_PASSWORD_REQUEST = 'USER/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'USER/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'USER/RESET_PASSWORD_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'USER/UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'USER/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'USER/UPDATE_PASSWORD_FAILURE';

export default {
  UPDATE_GENDER_REQUEST,
  UPDATE_BIRTHDAY_REQUEST,
  UPDATE_HEIGHT_REQUEST,
  UPDATE_WEIGHT_REQUEST,
  UPDATE_GOAL_REQUEST,

  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,

  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,

  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
};
