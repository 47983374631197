import React, { useEffect } from 'react';
import LayoutBlank from 'Layouts/LayoutBlank';
import Constants from 'Utils/Constants';
import PrivateRoute from 'Components/PrivateRoute/PrivateRoute';
import Footer from 'Components/Footer/Footer';
import Start from 'Pages/PageLoader/PageLoader';
import Toast from 'Utils/Toast';
import NotFound from 'Pages/NotFound/NotFoundPage';
import AppLoadingActions from 'Store/AppLoading/AppLoadingActions';
import PlanActions from 'Store/Plan/PlanActions';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenSelector } from 'Store/Auth/AuthSelector';
import { useInjectSaga } from 'redux-injectors';
import History from 'Utils/History';
import { getUserSelector } from 'Store/User/UserSelector';
import * as Sentry from '@sentry/browser';
import { privateRoutes, publicRoutes } from './Routes';
import saga from './AppSaga';
import SignUp from '../SignUp/SignUpPage';

Toast.configure();

const key = 'App';

export default () => {
  useInjectSaga({ key, saga });

  const token = useSelector(getTokenSelector);
  const user = useSelector(getUserSelector);
  const dispatch = useDispatch();

  const isBranchLink = window.location.href && window.location.href.includes('_branch_match_id');
  const isBranchAndNotLogged = isBranchLink && !token;

  const handleDeepLink = data => {
    if (!data || !data.data_parsed) return;


    let dataParsed = data.data_parsed;
    if (!Object.keys(data.data_parsed).length) {
      dataParsed = JSON.parse(data.data);
    }

    let to = null;

    if (dataParsed.data_type === 'meal') {
      to = {
        pathname: '/share/recipe',
        state: dataParsed,
      };
    } else if (dataParsed.data_type === 'workout') {
      to = {
        pathname: '/share/workout',
        state: dataParsed,
      };
    } else if (dataParsed.data_type === 'post') {
      to = {
        pathname: '/share/post',
        state: dataParsed,
      };
    }
    if (to) {
      History.push(to);
      if (isBranchAndNotLogged) {
        const currentPath = window.location.href;
        window.location.href = currentPath;
      }
    }
  };

  useEffect(() => {
    if (user) {
      window.branch.setIdentity(user.email);
      Sentry.setUser({
        email: user.email,
        id: user.id,
        username: `${user.first_name} ${user.last_name}`,
      });
    } else {
      window.branch.setIdentity('anonymous@fittcycle.com');
      Sentry.setUser({
        email: 'anonymous@fittcycle.com',
        id: '0',
        username: 'Guess',
      });
    }
    window.branch.init('key_live_goMd3H8Wz63h5iMEzZl0bcalvCp6yRYW', { nonce: 'GENERATED_NONCE_VALUE' }, (err, data) => {
      handleDeepLink(data);
    });

    window.branch.data((err, data) => {
      handleDeepLink(data);
    });

    window.branch.first((err, data) => {
      handleDeepLink(data);
    });


    if (token) {
      dispatch(PlanActions.getPlanRequest());
    } else {
      setTimeout(() => dispatch(AppLoadingActions.appLoadedSuccess()), 1000);
    }
  }, []);


  return (
    <StripeProvider apiKey={Constants.STRIPE_PUBLIC_KEY}>
      <>
        <Helmet titleTemplate="%s - TheFittCycle" defaultTitle="TheFittCycle">
          <meta name="description" content="TheFittCycle" />
        </Helmet>
        <LayoutBlank>
          <Switch>
            {isBranchAndNotLogged && <Route pathname="/" component={SignUp} />}
            {publicRoutes.map((route, index) => {
              const { path, component } = route;
              return <Route key={index} exact path={path} component={component} />;
            })}
            {privateRoutes.map((route, index) => {
              const { path, component } = route;
              return <PrivateRoute key={index} exact path={path} component={component} />;
            })}
            <Route path="" component={NotFound} />
          </Switch>
        </LayoutBlank>
        <Footer />
        <Start />
      </>
    </StripeProvider>
  );
};
