import Axios from 'axios';
import Constants, { API_URL } from 'Utils/Constants';
import { getStore } from 'Store/Store';
import { logoutRequest } from 'Store/Auth/AuthActions';
import { attach, RetryConfig } from 'retry-axios';


const ApiInstance = Axios.create({
  baseURL: API_URL,
  timeout: 60000,
});

const retryConfigConfig:RetryConfig = {
  retry: 3,
  retryDelay: 500,
  instance: ApiInstance,
};

ApiInstance.defaults.raxConfig = retryConfigConfig;

// eslint-disable-next-line no-unused-vars
const interceptorId = attach(ApiInstance);

ApiInstance.interceptors.request.use(async config => {
  if (Constants.IS_STAGING) {
    const tag = 'Request'.padEnd(16);
    const method = `[${config.method.toUpperCase()}]`.padEnd(10);
    const url = `${config.baseURL}${config.url}`;
    const title = `${tag}${method}${url}`;

    console.group(`%c ${title}`, ...['color: #03A9F4; font-weight: bold;']);

    config.headers && console.log('Headers'.padEnd(14), config.headers);
    config.params && console.log('Param'.padEnd(14), config.params);
    config.data && console.log('Body'.padEnd(14), config.data);
    console.groupEnd();
  }
  return config;
});

ApiInstance.interceptors.response.use(
  response => {
    if (Constants.IS_STAGING) {
      const tag = 'Request Success'.padEnd(16);
      const method = `[${response.config.method.toUpperCase()}]`.padEnd(10);
      const url = `${response.config.url}`;
      const title = `${tag}${method}${url}`;
      console.groupCollapsed(
        `%c ${title}`,
        ...['color: #4CAF50; font-weight: bold;'],
      );
      response.config.headers
        && console.log('Headers'.padEnd(14), response.config.headers);
      response.config.params
        && console.log('Param'.padEnd(14), response.config.params);
      response.config.data
        && console.log('Body'.padEnd(14), response.config.data);
      response.data && console.log('Status Code'.padEnd(14), response.status);
      response.data && console.log('Response Data'.padEnd(14), response.data);
      console.groupEnd();
    }
    return response.data;
  },
  error => {
    if (error.response) {
      const err = Error(Constants.INTERNAL_ERROR_MESSAGE);
      err['code'] = 500;

      if (error.response && error.response.data) {
        if (Array.isArray(error.response.data.errors) && error.response.data.errors.length) {
          const serverError = error.response.data.errors[0];
          err.message = `(${serverError.code}) - ${serverError.message}`;
          err.code = serverError.code;
        } else if (typeof error.response.data.error === 'object' && error.response.data.error.message) {
          const serverError = error.response.data.error;
          err.message = `(${serverError.code}) - ${serverError.message}`;
          err.code = serverError.code;
        }
      }

      if (err && err.code === 401) {
        getStore().dispatch(logoutRequest());
      }
      return Promise.reject(err);
    }

    if (Constants.IS_STAGING) {
      const tag = 'Request Error'.padEnd(16);
      const method = `[${error.config.method.toUpperCase()}]`.padEnd(10);
      const url = `${error.config.url}`;
      const title = `${tag}${method}${url}`;
      console.group(`%c ${title}`, ...['color: #F20404; font-weight: bold;']);
      error.config.headers
        && console.log('Headers'.padEnd(14), error.config.headers);
      error.config.params
        && console.log('Param'.padEnd(14), error.config.params);
      error.config.data && console.log('Body'.padEnd(14), error.config.data);
      error.message && console.log('Error Message'.padEnd(14), error.message);
      error.code && console.log('Error Code'.padEnd(14), error.code);
      console.groupEnd();
    }

    return Promise.reject(error);
  },
);


export default ApiInstance;
