import createReducer from 'Utils/CreateReducer';
import PlanActionTypes from './PlanActionTypes';

function getPlanSuccess(state, action) {
  state = action.payload;
  return state;
}
const planReducer = createReducer(null, {
  [PlanActionTypes.GET_PLANS_SUCCESS]: getPlanSuccess,
});

export default planReducer;
