import React from 'react';
import Assets from 'Assets';

// const IS_STAGING = process.env.NODE_ENV === 'production';
const IS_STAGING = false;

export const INFO_MAIL = 'info@thefittcylce.com';
export const API_URL = IS_STAGING ? 'https://fiit-cycle-staging.herokuapp.com' : 'https://api.thefittcycle.com';
export const DOMAIN_URL = IS_STAGING ? 'https://fiit-cycle-webapp-staging.herokuapp.com' : 'https://app.thefittcycle.com';

export const PUBLIC_FONTS_URL = `${DOMAIN_URL}/fonts`;

export const FIRST_PROMOTER_API_KEY = IS_STAGING ? 'af09c1d6a3a167b45a48921caa891621' : 'f19262c2dd16de38afbdd04a0b21773f';
export const FIRST_PROMOTER_WID = IS_STAGING ? 'a1c8600e7584c752440d' : '463aecfa81d099f81e10';
export const FIRST_PROMOTER_BASE_URL = 'https://firstpromoter.com/api/v1';

export const CUSTOMERIO_SITE_ID = IS_STAGING ? '0c381d68699181aa0c64' : 'c3b16e75bd5a06a649e0';
export const CUSTOMERIO_APP_KEY = IS_STAGING ? 'e70efc1d6d59bde5105a' : 'ff942af01ccd459f5a1f';

export const VERSION = 'v0.8.0';

const MOBILE_DEEPLINK = 'https://get.thefittcycle.com/download';

const UPDATE_PASSWORD_CALLBACK_URL = `${DOMAIN_URL}/password/update`;

const STRIPE_PUBLIC_KEY = IS_STAGING
  ? 'pk_test_QKxsNlzJa3WrNfk1H4Fezk1J'
  : 'pk_live_fDi4QzvUiFxNZ3IIuh6cGfll';

const PAYMENT_PLANS = [
  {
    title: 'Annual $390',
    subTitle: '2 months Free! / billed annually',
    buttonText: 'Select Plan',
    type: 'annual',
    price: '390$ / year',
  },
  {
    title: 'Monthly $39',
    subTitle: '2 months Free! / billed annually',
    buttonText: 'Select Plan',
    type: 'monthly',
    price: '390$ / year',
  },
  {
    title: 'Fall Special $39',
    subTitle: '2 months Free! / billed annually',
    buttonText: 'Select Plan',
    type: 'special',
    price: '390$ / year',
  },
];

const MENU_ITEMS = [
  {
    key: 1, title: 'Subscription', subTitle: 'Change / Update Billing', icon: 'fa fa-cog fa-lg',
  },
  {
    key: 2, title: 'Invoices', subTitle: 'Review & Download', icon: 'fa fa-file-o',
  },
  {
    key: 3, title: 'Open App', subTitle: 'View or Download', icon: 'fa fa-mobile fa-lg',
  },
  {
    key: 4, title: 'Contact', subTitle: 'Get Support', icon: 'fa fa-envelope',
  },
];

const MENU_ITEMS_WITHOUT_SUB = [
  {
    key: 3, title: 'Open App', subTitle: 'View or Download', icon: 'fa fa-mobile fa-lg',
  },
  {
    key: 4, title: 'Contact', subTitle: 'Get Support', icon: 'fa fa-envelope',
  },
];

const GOALS = [
  { title: 'Lose Weight', subTitle: 'Moderate fat loss while gaining muscle all over', key: 'Lose weight' },
  { title: 'Gain Muscle', subTitle: 'Build muscle mass all over', key: 'Gain muscle' },
  { title: 'Stay FITT', subTitle: 'Maintain your lean and tone physique', key: 'Stay FITT' },
  { title: 'FITT 2 CUT', subTitle: 'Advanced fat loss', key: 'FITT 2 CUT' },
];

const GENDER = ['male', 'female'];


const DEFAULT_BIRTHDAY = new Date(1985, 1, 1);

const TABLE_TABS = [{ title: 'Subscription', tab: '1' }, { title: 'Invoices', tab: '2' }];

const TABLE_HEADERS = ['Invoices', 'Plan Type', 'Amount', 'Date'];

const TABLE_DUMMY_DATA = [
  {
    id: 'Invoice #222',
    type: 'Monthly',
    amount: '$35.00',
    date: '04/11/19',
  },
  {
    id: 'Invoice #222',
    type: 'Monthly',
    amount: '$35.00',
    date: '04/11/19',
  },
  {
    id: 'Invoice #222',
    type: 'Monthly',
    amount: '$35.00',
    date: '04/11/19',
  },
];

const DUMMY_QUESTIONS = [
  {
    text: 'How do I sign up for The FITT Cycle?',
    answers: [
      <p>
        You can sign up at
        {' '}
        <a href="https://app.thefittcycle.com/" target="_blank">app.thefittcycle.com</a>
        {' '}
        to create an account. You can also download The FITT Cycle app in the Apple or Google stores.
      </p>,
    ],
  },
  {
    text: 'What platforms are The FITT Cycle available on?',
    answers: [
      'The FITT Cycle is available on iOS and Android.',
    ],
  },
  {
    text: 'What are the membership plans and how much do they cost?',
    answers: [
      'There is a monthly membership as well as an annual membership. Click HERE to see the cost options.',
    ],
  },
  {
    text: 'What day does the monthly subscription draft?',
    answers: [
      'Your subscription will draft on the day you enrolled each month.',
    ],
  },
  {
    text: 'What is the cancellation policy?',
    answers: [
      'You can cancel at any time, no strings attached.',
    ],
  },
  {
    text: 'How do I cancel my membership?',
    answers: [
      'Login to the website → Click the Subscriptions → At the bottom of the page under billing Select Cancel Account.',
    ],
  },
  {
    text: 'How do I upgrade my account?',
    answers: [
      'You can upgrade at any time. Just login to the website and go to Subscriptions and select the plan you would like. You will be asked if you are sure you want to do that.',
    ],
  },
  {
    text: 'What fitness level is The FITT Cycle designed for?',
    answers: [
      'The FITT Cycle is perfect for any fitness level. All exercises are modifiable to your needs.',
    ],
  },
  {
    text: 'Do I need a gym membership to do The FITT Cycle workouts?',
    answers: [
      'NO! With The FITT Cycle App, you will get access to both the gym workouts and home workouts so you can do The Daily FITT anywhere!',
    ],
  },
  {
    text: 'What type of equipment do I need for the home workouts?',
    answers: [
      'Typically you will need 2-3 sets of dumbbells, a bench, and resistance bands.',
    ],
  },
  {
    text: 'Can I participate in The FITT Cycle if I am breastfeeding?',
    answers: [
      'Yes! As long as you are cleared by your physician to exercise and do not have any restrictions.',
    ],
  },
  {
    text: 'Can I join The FITT Cycle if I am pregnant?',
    answers: [
      'Consult with your physician first, but if you are new to fasting and carb cycling we do not recommend starting while pregnant.',
    ],
  },
  {
    text: 'Will I get results if I just follow the nutrition?',
    answers: [
      'Absolutely!',
    ],
  },
  {
    text: 'What is “targeted training”?',
    answers: [
      'With The FITT Cycle we focus on Targeted Training. This means we are matching the type of exercises we are doing with our daily Carb Cycle. There are certain exercises you can do on either High carb/Low carb days to use those carbs to maximize muscle growth and fat loss! If you are not seeing results with your current regimen… you NEED TARGETED TRAINING!!',
    ],
  },
  {
    text: 'Do you provide a meal plan?',
    answers: [
      'The FITT Cycle primarily focuses on macro-management which is unique to each individual. We do provide a featured meal plan EACH week that is complete with High and Low carb options. You also have access to our recipe library with over 250+ high and low carb options with macros and calories included. All of The FITT Cycle recipes can be logged into your daily nutrition calculator within the app.',
    ],
  },
  {
    text: 'What is carb cycling?',
    answers: [
      'Carbohydrate Cycling is an intentional variation of carbohydrates each day. We will be alternating between the high carb and low carb days to strategically change your body from a sugar burner to a fat burner.',
    ],
  },
  {
    text: 'What is intermittent fasting or fasting intervals?',
    answers: [
      "Intermittent fasting or fasting intervals is not a diet, it's an eating schedule. Each day throughout the program we will be engaging in intermittent fasting... don't worry.. MOST of it is done while you are sleeping AND you get to eat every single day! You will just be eating in a shorter feeding window, rather than being able to graze all day!!",
    ],
  },
  {
    text: 'I have low blood sugar, can I still fast?',
    answers: [
      'I always recommend reaching out to your physician who typically manages your blood sugar to make sure you address WHY you are having low blood sugar. You can still do the program, but we will want to adjust your fasting time and make sure you are getting enough macronutrients to stabilize your blood sugar.',
    ],
  },
  {
    text: 'I have been told I have PCOS, will I get results?',
    answers: [
      'While everyone has a unique experience with The FITT Cycle, I have had SEVERAL clients diagnosed with PCOS go through the program and love their results.',
      'I’m a picky eater, will this program work for me?',
      'YES!!! This is the best thing about counting macros.. you get to eat the foods you love!! Whether you have allergies, intolerances, eat paleo, gluten-free, or dairy-free… you can make it fit your macros!',
    ],
  },
  {
    text: 'I’m a picky eater, will this program work for me?',
    answers: [
      'YES!!! This is the best thing about counting macros.. you get to eat the foods you love!! Whether you have allergies, intolerances, eat paleo, gluten free, or dairy free… you can make it fit your macros!',
    ],
  },
];

const SOCIAL_ICONS = [
  { to: 'https://www.facebook.com/lindsayrenefitness', icon: 'fa fa-facebook fa-lg' },
  { to: 'https://www.instagram.com/thefittcycle', icon: 'fa fa-instagram fa-lg' },
];

const TESTIMONIALS = [
  {
    index: 0,
    name: 'TIFFANY P.',
    text: 'I am a mom of 3 Kids. Before TFC I was tired, crabby, and very unhappy with my appearance. The FITT Cycle has given me my confidence back, I’m not tired anymore, and I’m just all around happier! My favorite day is leg day and I love the diversity of The FITT Cycle and all the amazing women and their support',
    image: Assets.testimonialImages.TiffanyP,
  },
  {
    index: 1,
    name: 'Jennifer W.',
    text: 'I joined The FITT Cycle and I LOVE it! After having my first child, I was at my heaviest weight ever! I tried all my old workout videos and some of the weight came off, but not all of it. After just a few months following The FITT Cycle all the weight came off and now I’m sculpting my body in ways I never thought I could! Hands down I’m in the best shape I’ve EVER been in and I LOVE it!!',
    image: Assets.testimonialImages.JenniferW,
  },
  {
    index: 2,
    name: 'Lindsay W.',
    text: "Full-time teacher, wife, and mom of 2 boys. When I started The Fitt Cycle I was so tired of going round and round with my diet and I was looking for something that was manageable for my life and something that would last. I have tried a lot of things but nothing has worked for me like The Fitt Cycle. My life and relationship with food has changed completely. Not to mention the amazing targeted workouts. I have lost weight/inches and I am really starting to tone up and gain muscle. More than anything I have gained confidence in myself that I didn't even know I had. I am starting to love myself and my body and that alone is worth every bit of it. I am a better wife and mom because of it too. I have said it 1000 times but joining this amazing community is the BEST decision I have ever made!!!",
    image: Assets.testimonialImages.LindsayW,
  },
  {
    index: 3,
    name: 'Jennifer B.',
    text: 'I started The FITT Cycle in November 2018 after realizing that I wasn’t accomplishing the results that I really wanted. At the time, I was doing P90X 5-6 days per week, but eating whatever I wanted in the thoughts that it would be fine because I was still working out. Upon joining TFC I was immediately floored at how little I was actually eating. I was already familiar with macros and how to count them, but I was consuming a very low-calorie count and wasn’t eating a clean as I should’ve been. This was causing me to hold on to the excess fat I had and I was literally going nowhere. This realization was huge for me! Once I added more calories (cleaner and more nutrient-dense calories mind you) my results came in like a freight train! I was losing the fat, gaining the muscles, and enjoying the healthier approach I had on my nutrition and food options. Fast forward about a year and carb cycling is like second nature now. Even my family knows what days are what and help to plan our suppers based on that knowledge! This program has been THE missing link in my life. I’ve always been active, always enjoying working out, and have mostly made good eating habits; TFC was just the cherry on top to pull it all together',
    image: Assets.testimonialImages.JenniferB,
  },
  {
    index: 4,
    name: 'Ashleigh K.',
    text: "Once I realized that the fad diets, yo-yo dieting, and quick fixes weren't working for me, I knew I had to find something real and sustainable. I was looking for something that didn't just make me look better, but something that made me feel better. Big secret: I followed Lindsay and The FITT Cycle for 6 months before I joined, how I wish I would have started earlier. One year later and I am amazingly healthy mentally and physically. I have not only lost 20 pounds, went from a size 10 to a size 4, but my hormones and blood work are back to normal! My biggest win through my whole FITT Cycle journey has been finding my smile and confidence! I'll never again consider going on a diet or giving up the foods I love. I will, however, enjoy more time around the dinner table with my husband, family, and friends without guilt or punishing myself! Thank you, Lindsay, for creating a lifestyle.",
    image: Assets.testimonialImages.AshleighK,
  },
  {
    index: 5,
    name: 'Kyndal C.',
    text: 'In January I set a goal for myself to focus on lower back and upper booty with The Fitt Cycle. Left picture was taken December 29th and right picture was taken May 29th. I’m not taking supplements, I still eat pizza, and I’m not doing hours of cardio!',
    image: Assets.testimonialImages.KyndalC,
  },


];

const LINDSAY_EMAIL = 'lindsay@lindsayrenefitness.com';

const PRICE_PER_MOUNT = 3900; // $39 USD

const CONTACT_MAIL = 'support@thefittcycle.com';

const HELP_LINK = 'https://help.thefittcycle.com';

const INTERNAL_ERROR_MESSAGE = 'Oops! An unexpected error occured. Please try again in little while. If it continues please report it at support@thefittcycle.com.';

const Constants = {
  INTERNAL_ERROR_MESSAGE,
  INFO_MAIL,
  PAYMENT_PLANS,
  MENU_ITEMS,
  MENU_ITEMS_WITHOUT_SUB,
  GOALS,
  DEFAULT_BIRTHDAY,
  IS_STAGING,
  MOBILE_DEEPLINK,
  TABLE_HEADERS,
  TABLE_DUMMY_DATA,
  GENDER,
  TABLE_TABS,
  DUMMY_QUESTIONS,
  SOCIAL_ICONS,
  STRIPE_PUBLIC_KEY,
  UPDATE_PASSWORD_CALLBACK_URL,
  FIRST_PROMOTER_BASE_URL,
  FIRST_PROMOTER_API_KEY,
  FIRST_PROMOTER_WID,
  CUSTOMERIO_SITE_ID,
  CUSTOMERIO_APP_KEY,
  TESTIMONIALS,
  LINDSAY_EMAIL,
  PRICE_PER_MOUNT,
  CONTACT_MAIL,
  HELP_LINK,
  VERSION,
};

export default Constants;
