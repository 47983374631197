import Toast from 'Utils/Toast';
import {
  put, takeLatest, call, select, delay,
} from 'redux-saga/effects';
// import { trackingSaleRequest } from 'Store/Lead/LeadActions';
// import { getLeadSelector } from 'Store/Lead/LeadSelector';
// import { getPlanSelector } from 'Store/Plan/PlanSelector';
import Api from 'Services/Api/Api';
import CustomerIO from 'Services/CustomerIO/CustomerIO';
import { getUserSelector } from 'Store/User/UserSelector';
import { reportCrash } from 'Utils/Utils';
import YourAccountActions from './YourAccountActions';
import YourAccountActionTypes from './YourAccountActionTypes';

function* getSubscriptionSaga() {
  try {
    const response = yield call(Api.getSubscription);

    yield put(YourAccountActions.getSubscriptionSuccess(response.subscriptions));
  } catch (error) {
    Toast.error(error);
    yield put(YourAccountActions.getSubscriptionFailure(error.message));
  }
}

function* getInvoicesSaga(action) {
  try {
    const response = yield call(Api.getInvoices, { user: action.payload });
    yield put(YourAccountActions.getInvoicesSuccess(response.invoices));
  } catch (error) {
    Toast.error(error);
    yield put(YourAccountActions.getInvoicesFailure(error.message));
  }
}

function* cancelSubscriptionSaga(action) {
  try {
    const { id, callback } = action.payload;
    const response = yield call(Api.cancelSubscription, id);
    CustomerIO.trackingCancelSub(response.subscription, true);
    yield put(YourAccountActions.cancelSubscriptionSuccess(response.subscription));
    Toast.success('Your subscription that will be canceled at the end of the current term.');
    if (typeof callback === 'function') {
      callback(response.subscription);
    }
  } catch (error) {
    Toast.error(error);
    yield put(YourAccountActions.cancelSubscriptionFailure(error.message));
    CustomerIO.trackingCancelSub(action.payload, false);
  }
}

function* downloadInvoiceSaga(action) {
  try {
    const response = yield call(Api.getInvoicePdfLink, action.payload);
    if (response && typeof response.url === 'string') {
      window.location.href = response.url;
    }
    yield delay(2);
    yield put(YourAccountActions.downloadInvoiceSuccess(action.payload));
  } catch (error) {
    yield put(YourAccountActions.downloadInvoiceFailure(error.message));
    reportCrash(error);
  }
}

function* changePlanSaga(action) {
  try {
    const response = yield call(Api.changePlan, action.payload);
    if (response.subscription.error) {
      throw Error(response.subscription.error);
    }

    const result = yield call(Api.getSubscription);
    yield put(YourAccountActions.getSubscriptionSuccess(result.subscriptions));
    yield put(YourAccountActions.changePlanSuccess(response.subscription));

    CustomerIO.trackingChangePlan(response.subscription, true);
    Toast.success('Your plan has been updated successfully');
  } catch (error) {
    yield put(YourAccountActions.changePlanFailure(error.message));
    Toast.error(error);
    CustomerIO.trackingChangePlan(null, false);
  }
}


function* checkoutSaga(action) {
  try {
    const {
      stripe, name, plan, customer, callback, promoCode, showToastSuccess = true,
    } = action.payload;

    if (!stripe) throw Error("Stripe.js hasn't loaded yet.");

    const res1 = yield call(stripe.createToken, { name });
    if (res1.error) {
      throw Error(res1.error.message);
    }

    const res2 = yield call(stripe.createPaymentMethod, 'card', { card: res1.token.id });
    if (!res2.paymentMethod.id) {
      throw Error('Fail to create payment method');
    }

    const res3 = yield call(Api.createPaymentIntent, { plan_id: plan.id, payment_method_id: res2.paymentMethod.id });
    if (typeof res3.error === 'string') {
      throw Error(res3.error);
    }


    const subscriptionPayload = {
      plan_id: plan.id, payment_intent_id: res3.payment_intent_id, customer, promo_code: promoCode, tmp_token: res1.token.id,
    };


    const res4 = yield call(Api.createSubscription, subscriptionPayload);
    if (typeof res4.error === 'string') {
      throw Error(res4.error);
    }

    const user = yield select(getUserSelector);

    yield put(YourAccountActions.getSubscriptionRequest());
    yield put(YourAccountActions.checkoutSuccess());

    if (user) {
      CustomerIO.updateCustomer(user.id, {
        plan: plan.id,
      });
    }
    if (showToastSuccess) {
      Toast.success('Your payment has been updated successfully!');
    }
    if (typeof callback === 'function') {
      const data = {
        subscription_id: res4?.subscription?.subscription_id ?? '',
        currency: 'USD',
        value: plan?.price ? plan?.price / 100 : '',

      };
      callback(data);
    }
  } catch (error) {
    yield put(YourAccountActions.checkoutFailure(error.message));
    reportCrash(error);
  }
}

export default function* signupWatcher() {
  yield takeLatest(YourAccountActionTypes.GET_INVOICES_REQUEST, getInvoicesSaga);
  yield takeLatest(YourAccountActionTypes.CHECKOUT_REQUEST, checkoutSaga);
  yield takeLatest(YourAccountActionTypes.GET_SUBSCRIPTION_REQUEST, getSubscriptionSaga);
  yield takeLatest(YourAccountActionTypes.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscriptionSaga);
  yield takeLatest(YourAccountActionTypes.DOWNLOAD_INVOICE_REQUEST, downloadInvoiceSaga);
  yield takeLatest(YourAccountActionTypes.CHANGE_PLAN_REQUEST, changePlanSaga);
}
