import createReducer from 'Utils/CreateReducer';
import AppFetchingActionTypes from './AppFetchingActionTypes';

// eslint-disable-next-line no-unused-vars
function appFetchingStart(state, action) {
  state = true;
  return state;
}

// eslint-disable-next-line no-unused-vars
function appFetchingEnd(state, action) {
  state = false;
  return state;
}

const appFetchingReducer = createReducer(null, {
  [AppFetchingActionTypes.APP_FETCHING_START]: appFetchingStart,
  [AppFetchingActionTypes.APP_FETCHING_END]: appFetchingEnd,
});

export default appFetchingReducer;
