import { createSelector } from 'reselect';

const appLoadingSelector = state => state.appLoading;


export const getAppLoadingSelector = createSelector(
  appLoadingSelector,
  appLoading => appLoading,
);

export default {
  getAppLoadingSelector,
};
