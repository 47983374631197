// @flow
import { toast, ToastOptions, ToastContent } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constants from './Constants';
import { reportCrash } from './Utils';

function configure() {
  toast.configure({
    autoClose: 8000,
  });
}
function success(content: ToastContent, options?: ToastOptions) {
  const defaultOptions = {
    className: 'toast__success',
    bodyClassName: 'toast__body',
    position: toast.POSITION.TOP_CENTER,
    ...options,
  };
  toast.success(content, defaultOptions);
}

function error(error: Error, options?: ToastOptions) {
  const defaultOptions: ToastOptions = {
    className: 'toast__error',
    bodyClassName: 'toast__body',
    position: toast.POSITION.TOP_CENTER,
    autoClose: 20000,
    closeOnClick: false,
    ...options,
  };

  if (error.code && error.message) {
    toast.error(error.message, defaultOptions);
  } else {
    const refId = reportCrash(error);
    const msg = `${Constants.INTERNAL_ERROR_MESSAGE} Please reference error id: ${refId}`;
    toast.error(msg, defaultOptions);
  }
}

const Toast = {
  success,
  error,
  configure,
};

export default Toast;
