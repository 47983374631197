import React from 'react';
import Assets from 'Assets';
import Input from 'Components/Input/Input';
import Button from 'Components/Button/Button';
import { PUBLIC_FONTS_URL } from 'Utils/Constants';
import { CheckoutFormProps } from 'Types';
import { Elements, injectStripe, CardElement } from 'react-stripe-elements';
import { getUserName } from 'Utils/Utils';
import './PaymentFormStyles.scss';

const inputStyle = {
  base: {
    fontSize: '20px',
    fontFamily: 'Averta',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    color: Assets.colors.battleshipGrey,
    '::placeholder': {
      color: Assets.colors.battleshipGrey,
      fontSize: '20px',
      fontFamily: 'Averta',
      fontWeight: 'normal',
      letterSpacing: 'normal',
    },
  },
  invalid: {
    color: Assets.colors.warmPink,
  },
};


class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;

    const defaultName = getUserName(user);

    this.state = {
      name: defaultName,
      error: '',
      term_cond: false,
      term_cond_error: '',
    };
  }


  handleSubmit = ev => {
    ev.preventDefault();
    const { name, term_cond } = this.state;
    const { stripe, onCheckout } = this.props;
    if (typeof name === 'string' && name.trim().length === 0) {
      this.setState({ error: 'Required' });
      return;
    }
    if (!term_cond) {
      this.setState({ term_cond_error: 'Please accept term and condition' });
      return;
    }
    onCheckout(stripe, name);
  };

  onChangeName = event => {
    this.setState({ name: event.target.value });
  }

  render() {
    const { name, error, term_cond_error } = this.state;
    const { paymentError, isProcessing, buttonText = 'Complete Purchase' } = this.props;
    return (
      <form className="paymentForm" onSubmit={this.handleSubmit}>
        <Input
          name="name"
          type="text"
          placeholder="Card Holder Name"
          defaultValue={name}
          onChange={this.onChangeName}
          error={error}
        />
        <CardElement style={inputStyle} className="cardNumber" hidePostalCode />
        {typeof paymentError === 'string' && paymentError !== '' && (
          <div className="input-error">{paymentError}</div>
        )}
        <label className="container">
          <a target="_blank" href="/term">I have read and agree to the terms and conditions</a>
          <input type="checkbox" onChange={e => this.setState({ term_cond: e.target.checked, term_cond_error: '' })} />
          <span className="checkmark" />
        </label>
        {term_cond_error !== '' && <label className="input-error">{term_cond_error}</label>}
        <Button
          type="submit"
          loading={isProcessing}
          block
          className="col-md-8 offset-md-2 col-sm-10 offset-sm-1 "
        >
          {buttonText}
        </Button>
      </form>
    );
  }
}
const PaymentFormInjectStripe = injectStripe(PaymentForm);


const CheckoutForm: React.SFC<CheckoutFormProps> = props => (
  <Elements fonts={[
    {
      family: 'Averta', weight: 'normal', style: 'normal', src: `url(${PUBLIC_FONTS_URL}/Averta-Regular.otf)`,
    },
    {
      family: 'Averta', weight: '800', style: 'normal', src: `url(${PUBLIC_FONTS_URL}/Averta-Bold.otf)`,
    },
  ]}
  >
    <PaymentFormInjectStripe {...props} />
  </Elements>
);


export default CheckoutForm;
