import moment from 'moment';
import { formatMoneyString, reportCrash } from 'Utils/Utils';

const SUBSCRIPTION_EVENT__CANCEL = 'SUBSCRIPTION_EVENT__CANCEL';
const SUBSCRIPTION_EVENT__UPDATE = 'SUBSCRIPTION_EVENT__UPDATE';
const SUBSCRIPTION_EVENT__CHANGE_PLAN = 'SUBSCRIPTION_EVENT__CHANGE_PLAN';
/**
*
* @param {import('Types').UserInfo} userProfile
*/
export function createCustomer(userProfile) {
  try {
    const created_at = moment().unix().toString();
    const {
      email = '', first_name = '', last_name = '', timezone = '', birthday = '', gender = '', phone = '', remote_avatar_url: avatar = '', id,
    } = userProfile;

    if (!id) return;

    const body = {
      id,
      email,
      first_name,
      last_name,
      timezone,
      birthday,
      gender,
      phone,
      avatar,
      last_login: created_at,
      created_at,
      plan: '',
    };
    // eslint-disable-next-line no-underscore-dangle
    window._cio.identify(body);
  } catch (error) {
    reportCrash(error);
  }
}


/**
*
* @param {import('Types').UserInfo} userProfile
*/
export function updateCustomer(id, payload = {}) {
  try {
    const body = {
      id,
      ...payload,
    };
    // eslint-disable-next-line no-underscore-dangle
    window._cio.identify(body);
  } catch (error) {
    reportCrash(error);
  }
}

export function trackingEvent(eventName, data) {
  // eslint-disable-next-line no-underscore-dangle
  window._cio.track(eventName, data);
}
/**
 *
 * @param {import('Types').CBSubscription} subscription
 */
export function trackingCancelSub(subscription, success = false) {
  trackingEvent(SUBSCRIPTION_EVENT__CANCEL, {
    id: subscription ? subscription.id : '',
    plan_id: subscription ? subscription.plan_id : '',
    action_type: 'UPDATE',
    customer_id: subscription ? subscription.customer_id : '',
    status: subscription ? subscription.status : '',
    cancelled_at: subscription.cancelled_at ? moment.unix(subscription.cancelled_at).format('MMM DD, YYYY') : '',
    plan_unit_price: subscription && subscription.plan_unit_price ? formatMoneyString(subscription.plan_unit_price / 100) : '',
    plan_amount: subscription && subscription.plan_amount ? formatMoneyString(subscription.plan_amount / 100) : '',
    billing_period: subscription && subscription.billing_period ? subscription.billing_period.toString() : '',
    billing_period_unit: subscription && subscription.billing_period_unit ? subscription.billing_period_unit.toString() : '',
    current_term_start: subscription.current_term_start ? moment.unix(subscription.current_term_start).format('MMM DD, YYYY') : '',
    current_term_end: subscription.current_term_end ? moment.unix(subscription.current_term_end).format('MMM DD, YYYY') : '',
    success,
  });
}


/**
 *
 * @param {import('Types').CBSubscription} subscription
 */
export function trackingUpdateSub(subscription, success = true) {
  trackingEvent(SUBSCRIPTION_EVENT__UPDATE, {
    id: subscription ? subscription.id : '',
    plan_id: subscription ? subscription.plan_id : '',
    action_type: 'UPDATE',
    customer_id: subscription ? subscription.customer_id : '',
    status: subscription ? subscription.status : '',
    cancelled_at: subscription.cancelled_at ? moment.unix(subscription.cancelled_at).format('MMM DD, YYYY') : '',
    plan_unit_price: subscription && subscription.plan_unit_price ? formatMoneyString(subscription.plan_unit_price / 100) : '',
    plan_amount: subscription && subscription.plan_amount ? formatMoneyString(subscription.plan_amount / 100) : '',
    billing_period: subscription && subscription.billing_period ? subscription.billing_period.toString() : '',
    billing_period_unit: subscription && subscription.billing_period_unit ? subscription.billing_period_unit.toString() : '',
    current_term_start: subscription.current_term_start ? moment.unix(subscription.current_term_start).format('MMM DD, YYYY') : '',
    current_term_end: subscription.current_term_end ? moment.unix(subscription.current_term_end).format('MMM DD, YYYY') : '',
    success,

  });
}


/**
 *
 * @param {import('Types').CBSubscription} subscription
 */
export function trackingChangePlan(subscription, success = true) {
  trackingEvent(SUBSCRIPTION_EVENT__CHANGE_PLAN, {
    id: subscription ? subscription.id : '',
    plan_id: subscription ? subscription.plan_id : '',
    action_type: 'CHANGE_PLAN',
    customer_id: subscription ? subscription.customer_id : '',
    status: subscription ? subscription.status : '',
    cancelled_at: subscription.cancelled_at ? moment.unix(subscription.cancelled_at).format('MMM DD, YYYY') : '',
    plan_unit_price: subscription && subscription.plan_unit_price ? formatMoneyString(subscription.plan_unit_price / 100) : '',
    plan_amount: subscription && subscription.plan_amount ? formatMoneyString(subscription.plan_amount / 100) : '',
    billing_period: subscription && subscription.billing_period ? subscription.billing_period.toString() : '',
    billing_period_unit: subscription && subscription.billing_period_unit ? subscription.billing_period_unit.toString() : '',
    current_term_start: subscription.current_term_start ? moment.unix(subscription.current_term_start).format('MMM DD, YYYY') : '',
    current_term_end: subscription.current_term_end ? moment.unix(subscription.current_term_end).format('MMM DD, YYYY') : '',
    success,

  });
}

export default {
  createCustomer,
  trackingEvent,
  trackingCancelSub,
  trackingUpdateSub,
  updateCustomer,
  trackingChangePlan,
};
