import React from 'react';
import constants from 'Utils/Constants';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { SlideDown } from 'react-slidedown';
import { UserMenuProps } from 'Types';
import './UserMenuStyles.scss';
import 'react-slidedown/lib/slidedown.css';
import { getUserName } from 'Utils/Utils';

class UserMenu extends React.PureComponent<UserMenuProps> {
  constructor(props) {
    super(props);
    const { user } = this.props;
    const isValidAvatar = user
    && typeof user.remote_avatar_url === 'string'
    && user.remote_avatar_url.length;
    const name = getUserName(user);

    this.state = {
      opened: false,
      isValidAvatar,
      name,
    };
  }

  toggle = () => {
    const { opened } = this.state;
    this.setState({ opened: !opened });
  };

  onSelected = index => {
    const {
      onSubscriptionPressed,
      onInvoicePressed,
      onContactPressed,
      onOpenAppPressed,
    } = this.props;
    let onClick = null;
    if (index === 1) {
      onClick = onSubscriptionPressed;
    } else if (index === 2) {
      onClick = onInvoicePressed;
    } else if (index === 3) {
      onClick = onOpenAppPressed;
    } else if (index === 4) {
      onClick = onContactPressed;
    }
    this.toggle();
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  render() {
    const { opened, name, isValidAvatar } = this.state;
    const { user, onLogout, hasAppleSubscription } = this.props;
    const items = hasAppleSubscription ? constants.MENU_ITEMS_WITHOUT_SUB : constants.MENU_ITEMS;

    return (
      <Dropdown group isOpen={opened} toggle={this.toggle}>
        <DropdownToggle
          tag="span"
          onClick={this.toggle}
          data-toggle="dropdown"
          aria-expanded={opened}
        >
          <div className="userMenu pointer">
            {isValidAvatar ? (
              <img
                src={user.remote_avatar_url}
                className="userMenu__avatar"
                alt="user_image"
                onError={() => this.setState({ isValidAvatar: false })}
              />
            ) : (
              <div className="userMenu__avatar center">
                <i className="fa fa-user" aria-hidden="true" />
              </div>
            )}

            <div className="userMenu__textGroup">
              <div>{name}</div>
              <div>Fitt Account</div>
            </div>
            <div className={`userMenu__chevron ${opened ? 'active' : ''}`}>
              <i className={`fa ${opened ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu style={{ border: 'none', padding: 0, backgroundColor: 'transparent' }}>
          <SlideDown className="my-dropdown-slidedown">
            <div className="userMenu__menu">
              <div>Account</div>
              <div className="divider" />
              <div>
                {items.map((item, index) => {
                  const {
                    title, subTitle, icon, key,
                  } = item;

                  return (
                    <div
                      key={index}
                      className="rowItem clickable"
                      onClick={() => this.onSelected(key)}
                    >
                      <div className="icon">
                        <span className={icon} />
                      </div>
                      <div className="textGroup">
                        <div>{title}</div>
                        <div>{subTitle}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="divider" />
              <div className="clickable" onClick={onLogout}>
                Log Out
              </div>
            </div>
          </SlideDown>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default UserMenu;
