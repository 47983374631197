import React from 'react';
import Assets from 'Assets';
import Constants from 'Utils/Constants';
import Header from 'Components/Header/Header';
import Button from 'Components/Button/Button';
import { useSelector } from 'react-redux';
import { getUserName, createMailtoLink } from 'Utils/Utils';
import { getUserSelector } from 'Store/User/UserSelector';
import './DownloadStyles.scss';

export default props => {
  const { location } = props;
  const user = useSelector(getUserSelector);
  const name = getUserName(user);
  const handleDownloadApp = () => {
    if (location?.state?.data) {
      // @ts-ignore
      window.fbq('track', 'StartTrial', location?.state?.data);
    }
    window.location.href = Constants.MOBILE_DEEPLINK;
  };
  const onContact = () => {
    window.location.href = createMailtoLink(Constants.CONTACT_MAIL);
  };
  return (
    <div className="Download">
      <Header RightComponent={(
        <div onClick={onContact} className="link touchable">
          <span>Need Help?</span>
        </div>
      )}
      />
      <div className="container">
        <div className="col-lg-7 col-sm-12 align-items-center Download__content">
          <h1>{`Yay! ${name}, lets get started`}</h1>
          <h3>
              You did it, now download the app and login to start the program. It’s that easy to
              start achieving your goals.
          </h3>
          <Button onClick={handleDownloadApp}>Download the Mobile App</Button>
          <img src={Assets.images.download} alt="The Fitt Cycle App" />
        </div>
      </div>
    </div>
  );
};
