import queryString from 'query-string';
import currency from 'currency.js';
import { CBSubscriptionList, CBSubscription } from 'Types';
import * as Sentry from '@sentry/browser';

export const getUserName = user => {
  if (!user) return '';
  const firstName = user.first_name || '';
  const lastName = user.last_name || '';
  let name = firstName;
  if (lastName.length) {
    name += ` ${lastName}`;
  }

  return name;
};


export const formatMoney = (value, options) => currency(value, options).value;

export const formatMoneyString = (value, options) => currency(value, options).format(true);

export const parseUrl = url => queryString.parse(url);

export const isValidSubscription = (subscription: CBSubscription) => {
  if (subscription == null) return false;

  let isValid = false;
  switch (subscription.status) {
    case 'future': // The Subscription is scheduled to start in a future date.
    case 'in_trial': // The subscription is in trial.
    case 'active': // The subscription is in active state and will be charged at start of each term based on the recurring items(plan & addons etc.,).
    case 'non_renewing': // The subscription will be cancelled at end of the current term.
      isValid = true;
      break;
    case 'paused': // The subscription is paused. No new recurring actions will be allowed, but any pending payments will be collected.
    case 'cancelled': // The subscription has been cancelled. No new recurring actions will take place, but any pending payments will be collected.
      isValid = false;
      break;
    default:
      break;
  }

  return isValid;
};

export const getValidSubscription = (subscriptions: CBSubscriptionList) => {
  if (!Array.isArray(subscriptions) || subscriptions.length === 0) return null;

  const validSub = subscriptions.find(sub => isValidSubscription(sub));

  return validSub;
};

export const getCanceledTime = (subscription: CBSubscription) => {
  if (!subscription) return null;

  return subscription.cancelled_at;
};

/** @param {import('Types').CBPlan} plan */
export const formatPlanDescription = plan => {
  if (!plan) return '';


  return `${plan.name} ${formatMoneyString(plan.price / 100)} / ${plan.period} ${plan.period_unit}`;
};


export const toSearchString = (searchParams = {}) => Object.keys(searchParams).map(key => `${key}=${encodeURIComponent(searchParams[key])}`).join('&');

export const createMailtoLink = (email, headers) => {
  let link = `mailto:${email}`;
  if (headers) {
    link += `?${toSearchString(headers)}`;
  }
  return link;
};

export const reportCrash = error => Sentry.captureException(error);

export default {};
