export const initialState = {
  isFetching: false,
  invoices: null,
  isProcessingPayment: false,
  lead: null,
  error: null,
  subscriptions: [],
  isCancellingSub: false,
  isCheckingSub: true,
  isChangingPlan: false,
  isShowCancelMessageSuccess: false,
  invoiceDownloadState: {
    id: -1,
    isDownloading: false,
  },
  currentSubscription: null,

};

export default initialState;
