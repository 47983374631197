
export const CREATE_LEAD_REQUEST = 'LEAD/CREATE_LEAD_REQUEST';
export const CREATE_LEAD_SUCCESS = 'LEAD/CREATE_LEAD_SUCCESS';
export const CREATE_LEAD_FAILURE = 'LEAD/CREATE_LEAD_FAILURE';

export const TRACKING_SALE_REQUEST = 'LEAD/TRACKING_SALE_REQUEST';
export const TRACKING_SALE_SUCCESS = 'LEAD/TRACKING_SALE_SUCCESS';
export const TRACKING_SALE_FAILURE = 'LEAD/TRACKING_SALE_FAILURE';


export default {
  CREATE_LEAD_REQUEST, CREATE_LEAD_SUCCESS, CREATE_LEAD_FAILURE, TRACKING_SALE_REQUEST, TRACKING_SALE_SUCCESS, TRACKING_SALE_FAILURE,
};
