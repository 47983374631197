import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'Utils/History';
import authReducer from './Auth/AuthReducer';
import userReducer from './User/UserReducer';
import appLoadingReducer from './AppLoading/AppLoadingReducer';
import planReducer from './Plan/PlanReducer';
import leadReducer from './Lead/LeadReducer';
import appFetchingReducer from './AppFetching/AppFetchingReducer';
import appleSubscriptionReducer from './AppleSubscription/AppleSubscriptionReducer';
import { LOGOUT_REQUEST } from './Auth/AuthActionTypes';

export default function createReducer(injectedReducers = {}) {
  const appReducer = combineReducers({
    token: authReducer,
    user: userReducer,
    appLoading: appLoadingReducer,
    isFetching: appFetchingReducer,
    plans: planReducer,
    lead: leadReducer,
    appleSubscriptions: appleSubscriptionReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  const rootReducer = (state, action) => {
    if (action.type === LOGOUT_REQUEST) {
      state = undefined;
    }
    return appReducer(state, action);
  };
  return rootReducer;
}
