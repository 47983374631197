
import ApiInstance from './ApiInstance';

export const login = payload => ApiInstance.post('/api/sign_in', payload);
// export const logout = payload => ApiInstance.delete('/api/sign_out', payload);
export const resetPassword = payload => ApiInstance.post('/api/passwords', payload);
export const updatePassword = payload => ApiInstance.put('/api/passwords/update_password', payload);
export const signup = payload => ApiInstance.post('/api/users/sign_up', payload);
export const updateProfile = payload => ApiInstance.post('/api/users', payload);
export const getProfile = payload => ApiInstance.get('/api/users/my_profile', payload);
export const getPlans = payload => ApiInstance.get('/api/subscriptions/plans', payload);
export const getInvoices = payload => ApiInstance.get('/api/subscriptions/my_invoices', payload);
export const getInvoicePdfLink = id => ApiInstance.get(`/api/subscriptions/invoice_pdf?invoice_id=${id}`);
export const createPaymentIntent = payload => ApiInstance.post('/api/subscriptions/payment_intent', payload);
export const createSubscription = payload => ApiInstance.post('/api/subscriptions/subscription', payload);
export const cancelSubscription = id => ApiInstance.post('/api/subscriptions/cancel', { subscription_id: id });
export const changePlan = payload => ApiInstance.post('/api/subscriptions/change_plan', payload);
export const getSubscription = () => ApiInstance.get('/api/subscriptions/my_subscriptions');
export const getCoupon = payload => ApiInstance.get('/api/subscriptions/coupon', { params: { promo_code: payload } });
export const getAppleSubscriptions = () => ApiInstance.get('/api/apple_subscriptions/my_receipts');
export default {
  login,
  // logout,
  signup,
  resetPassword,
  updatePassword,
  updateProfile,
  getProfile,
  getPlans,
  getInvoices,
  createPaymentIntent,
  createSubscription,
  getCoupon,
  getSubscription,
  getInvoicePdfLink,
  cancelSubscription,
  changePlan,
  getAppleSubscriptions,
};
