import React from 'react';
import classnames from 'classnames';
import './ButtonStyles.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean;
  outline: boolean;
  small: boolean;
  block: boolean
}

const Button: React.SFC<ButtonProps> = props => {
  const {
    outline, small, className, loading, disabled, children, block, ...rest
  } = props;

  const cn = classnames('button', className, {
    'button-outline': outline,
    'button-small': small,
    'button-block': block,
  });
  return ((
    <button type="button" {...rest} className={cn} disabled={disabled || loading}>
      {loading && <i className="fa fa-refresh fa-spin mx-auto" />}
      {!loading && children}
    </button>
  ));
};

export default Button;
