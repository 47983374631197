import React from 'react';
import classnames from 'classnames';
import './InputStyles.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error: string;
  errorTextColor: string;
  LeftComponent: React.ReactElement;
  RightComponent: React.ReactElement;

}

const Input: React.SFC<InputProps> = props => {
  const {
    error,
    errorTextColor: color,
    LeftComponent = null,
    RightComponent = null,
    className,
    ...rest
  } = props;

  const hasLeftIcon = LeftComponent != null;
  const hasRightIcon = RightComponent != null;


  const cn = classnames('input', {
    'has-left-icon': hasLeftIcon,
    'has-right-icon': hasRightIcon,
  });
  return (
    <div className={className}>
      <div className={cn}>
        {hasLeftIcon && <div className="input-left-icon">{LeftComponent}</div>}
        <input {...rest} />
        {hasRightIcon && <div className="input-right-icon">{RightComponent}</div>}
      </div>
      {typeof error === 'string' && error !== '' && <div className="input-error" style={{ color }}>{error}</div>}
    </div>
  );
};

export default Input;
