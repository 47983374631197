import React, { useState } from 'react';
import Button from 'Components/Button/Button';
import Header from 'Components/Header/Header';
import ButtonLogout from 'Components/Button/ButtonLogout';
import { getUserSelector } from 'Store/User/UserSelector';
import { getAppFetchingSelector } from 'Store/AppFetching/AppFetchingSelector';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'redux-injectors';
import { updateHeightRequest } from 'Store/User/UserActions';
import saga from './SignUp3Saga';
import './SignUp3Styles.scss';

const key = 'signup3';

export default () => {
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const isFetching = useSelector(getAppFetchingSelector);
  const user = useSelector(getUserSelector);
  const defaultHeightFt = user && user.height_ft !== '' ? user.height_ft : '4';
  const defaultHeightIn = user && user.height_in !== '' ? user.height_in : '3';
  const [height_ft, setHeightFt] = useState(defaultHeightFt);
  const [height_in, setHeightIn] = useState(defaultHeightIn);
  const isInvalid = height_in === '' || height_ft === '';

  const onContinue = event => {
    event.preventDefault();
    dispatch(updateHeightRequest({ height_in, height_ft }));
  };


  return (
    <div className="SignUp3">
      <Header RightComponent={<ButtonLogout />} />
      <div className="container">
        <div className="col-lg-5 col-md-8 col-sm-12 p-sm-2 align-self-center mx-auto">
          <h6>Step 3 of 6</h6>
          <h1>How tall are you?</h1>
          <div className="row justify-content-center SignUp3__control">
            <div className="row justify-content-center align-items-end">
              <input
                name="height_ft"
                defaultValue={height_ft}
                onChange={({ target }) => setHeightFt(target.value)}
                size={2}
                maxLength={2}
                placeholder="4"
                type="text"
                pattern="[0-9]*"
              />
              <span>FT</span>
            </div>
            <div className="row justify-content-center align-items-end">
              <input
                name="height_in"
                defaultValue={height_in}
                onChange={({ target }) => setHeightIn(target.value)}
                size={2}
                maxLength={2}
                placeholder="7"
                type="text"
                pattern="[0-9]*"
              />
              <span>IN</span>
            </div>
          </div>
          <Button
            disabled={isInvalid}
            loading={isFetching}
            type="button"
            onClick={onContinue}
          >
              Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
