
import {
  put, call, takeLatest, all,
} from 'redux-saga/effects';


import { push } from 'connected-react-router';
import PlanActionTypes from 'Store/Plan/PlanActionTypes';
import PlanActions from 'Store/Plan/PlanActions';
import AppLoadingActions from 'Store/AppLoading/AppLoadingActions';
import AuthActionTypes from 'Store/Auth/AuthActionTypes';
import LeadActionTypes from 'Store/Lead/LeadActionTypes';
import LeadActions from 'Store/Lead/LeadActions';
import UserActions from 'Store/User/UserActions';
import AppleSubscriptionActions from 'Store/AppleSubscription/AppleSubscriptionActions';
import Api from 'Services/Api/Api';
import FirstPromoter from 'Services/FirstPromoter/FirstPromoter';
import ApiInstance from 'Services/Api/ApiInstance';
import Constants from 'Utils/Constants';
import { reportCrash } from 'Utils/Utils';
import moment from 'moment';

function* getPlanSaga() {
  try {
    const res = yield all([call(Api.getPlans), call(Api.getProfile), call(Api.getAppleSubscriptions)]);
    const activePlans = Array.isArray(res[0]) ? res[0].filter(plan => plan.status === 'active') : [];
    const receipts = res[2] && Array.isArray(res[2].apple_receipts) ? res[2].apple_receipts.filter(receipt => {
      if (!receipt || !receipt.expires_date) return false;
      let expireDate = moment(receipt.expires_date).unix();

      const now = moment().unix();


      if (receipt.cancellation_date_ms != null && typeof receipt.cancellation_date_ms === 'string') {
        expireDate = parseInt(receipt.cancellation_date_ms, 10);
      }
      return expireDate >= now;
    }) : [];

    yield put(PlanActions.getPlanSuccess(activePlans));
    yield put(UserActions.updateProfileSuccess(res[1].user));
    yield put(AppleSubscriptionActions.getAppleSubscriptionSuccess(receipts));
    yield put(AppLoadingActions.appLoadedSuccess());
  } catch (error) {
    yield put(PlanActions.getPlanFailure(error.message));
    yield put(AppLoadingActions.appLoadedSuccess());
    reportCrash(error);
  }
}


function* trackingSaleSaga(action) {
  try {
    const {
      plan, customer_id, lead, plans,
    } = action.payload;

    if (!lead || !plan || !Array.isArray(plans) || plans.length === 0) {
      return;
    }

    const {
      id, promotion, promoter, email, username,
    } = lead;

    const { ref_id } = promotion;

    const event_id = `${id}-${promotion.id}-${promoter.id}`;

    const payload = {
      uid: customer_id,
      event_id,
      plan: plan.id,
      amount: Constants.PRICE_PER_MOUNT,
      ref_id,
      email,
      username,
    };
    yield call(FirstPromoter.trackingSale, payload);

    yield put(LeadActions.trackingSaleSuccess());
  } catch (error) {
    yield put(LeadActions.trackingSaleFailure(error.message));
    reportCrash(error);
  }
}

function* logoutSaga() {
  try {
    yield call(Api.logout);
    throw Error('Logout');
  } catch (error) {
    ApiInstance.defaults.headers.common.Authorization = '';
    yield put(push('/'));
  }
}


export default function* appWatcher() {
  yield takeLatest(PlanActionTypes.GET_PLANS_REQUEST, getPlanSaga);
  yield takeLatest(AuthActionTypes.LOGOUT_REQUEST, logoutSaga);
  yield takeLatest(LeadActionTypes.TRACKING_SALE_REQUEST, trackingSaleSaga);
}
