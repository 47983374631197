import React from 'react';
import ButtonLogout from 'Components/Button/ButtonLogout';
import Header from 'Components/Header/Header';


import './TermsStyles.scss';


export default () => (
  <div className="Terms">
    <Header RightComponent={<ButtonLogout />} />
    <div className="container">
      <h1 className="Terms__title">
      Disclaimer
      </h1>
      <p className="Terms__text">
      IMPORTANT WARNING: I’m not a doctor, and I don’t know your personal health history. The information in my videos, on my website and in all programs provided is only a suggestion. Please consult your physician and use your own judgment before trying any exercise or diet program.

      copyright: Lindsay Rene Fitness. Unauthorized use and/or duplication of this material without express and written permission from the author and/or owner is strictly prohibited.
      </p>
    </div>
  </div>
);
