import createReducer from 'Utils/CreateReducer';
import { clearAll } from 'Store/LocalStorage';
import AuthActionTypes from './AuthActionTypes';

function loginSuccess(state, action) {
  state = action.payload.token;
  return state;
}

// eslint-disable-next-line no-unused-vars
function loginFailure(state, action) {
  state = null;
  clearAll();
  return state;
}

const authReducer = createReducer(null, {
  [AuthActionTypes.LOGIN_SUCCESS]: loginSuccess,
  [AuthActionTypes.SIGNUP_SUCCESS]: loginSuccess,
  [AuthActionTypes.LOGIN_FAILURE]: loginFailure,
  [AuthActionTypes.LOGOUT_REQUEST]: loginFailure,
});

export default authReducer;
