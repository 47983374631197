import React, { useState } from 'react';
import Button from 'Components/Button/Button';
import Header from 'Components/Header/Header';
import ButtonLogout from 'Components/Button/ButtonLogout';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'redux-injectors';
import { getAppFetchingSelector } from 'Store/AppFetching/AppFetchingSelector';
import { getUserSelector } from 'Store/User/UserSelector';
import { updateWeightRequest } from 'Store/User/UserActions';
import saga from './SignUp4Saga';
import './SignUp4Styles.scss';

const key = 'signup4';

export default () => {
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const isFetching = useSelector(getAppFetchingSelector);
  const user = useSelector(getUserSelector);
  const defaultWeight = user && typeof user.weight === 'string' && user.weight !== '' ? parseFloat(user.weight).toFixed(0) : '120';
  const [weight, setWeight] = useState(defaultWeight);
  const isInvalid = weight === '';

  const onContinue = event => {
    event.preventDefault();
    dispatch(updateWeightRequest(weight));
  };

  const onChange = event => {
    setWeight(event.target.value);
  };

  return (
    <div className="SignUp4">
      <Header RightComponent={<ButtonLogout />} />
      <div className="container">
        <div className="col-lg-5 col-md-8 col-sm-12 p-sm-2 align-self-center mx-auto">
          <h6 className="">Step 4 of 6</h6>
          <h1>How much do you weigh?</h1>
          <div className="d-flex flex-column align-items-center">
            <div className="row justify-content-center align-items-end SignUp4__control">
              <input
                name="weight"
                onChange={onChange}
                size={3}
                maxLength={3}
                defaultValue={weight}
                placeholder="120"
                type="text"
                pattern="[0-9]*"
              />
              <span>LBS</span>
            </div>
            {isInvalid && <div className="mx-auto mt-2 input-error">Required</div>}
          </div>
          <Button
            loading={isFetching}
            disabled={isInvalid}
            type="button"
            onClick={onContinue}
          >
              Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
