import { createSelector } from 'reselect';

const userSelector = state => state.user;

export const getUserSelector = createSelector(
  userSelector,
  user => user,
);


export default {
  getUserSelector,
};
