import React, { useState } from 'react';
import PaymentForm from 'Components/PaymentForm/PaymentForm';
import Header from 'Components/Header/Header';
import Input from 'Components/Input/Input';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { formatMoneyString } from 'Utils/Utils';
import { useInjectSaga, useInjectReducer } from 'redux-injectors';
import { getUserSelector } from 'Store/User/UserSelector';
import { checkoutRequest } from 'Pages/YourAccount/YourAccountActions';
import { getProcessingPaymentSelector, getErrorSelector } from 'Pages/YourAccount/YourAccountSelector';
import saga from 'Pages/YourAccount/YourAccountSaga';
import reducer from 'Pages/YourAccount/YourAccountReducer';
import Api from 'Services/Api/Api';
import './PaymentDetailStyles.scss';

const searchAPI = text => Api.getCoupon(text);

const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 500);

const key = 'yourAccount';

export default props => {
  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const isProcessingPayment = useSelector(getProcessingPaymentSelector);
  const error = useSelector(getErrorSelector);

  const { location, history } = props;
  const [searching, setSearching] = useState(false);
  const [promoCode, setPromoCode] = useState(false);
  const [promoCodeError, setPromoCodeError] = useState('');
  const [discountPercent, setDiscountPercent] = useState(null);

  const onCheckoutSuccess = data => {
    history.push('/download', { data });
  };

  const onCheckout = (stripe, name) => {
    const { plan } = location.state;
    const {
      first_name, last_name, phone, email,
    } = user;
    const customer = {
      first_name, last_name, phone, email,
    };


    const validPromoCode = promoCodeError === '' && promoCode !== '' ? promoCode : null;

    dispatch(checkoutRequest({
      stripe,
      name,
      customer,
      plan,
      promoCode: validPromoCode,
      callback: onCheckoutSuccess,
      showToastSuccess: false,
    }));
  };


  const onTextChange = async event => {
    const { value } = event.target;

    setPromoCode(value);
    setSearching(true);
    const result = await searchAPIDebounced(value);
    const coupon = result.coupon;

    setSearching(false);
    if (!coupon || coupon.status !== 'active') {
      const error = 'Sorry, that promo code is invalid';
      setPromoCodeError(error);
      setDiscountPercent(null);
      return;
    }
    let percent;
    if (coupon.discount_amount.indexOf('%') >= 0) percent = parseFloat(coupon.discount_amount) / 100.0;
    else percent = parseFloat(coupon.discount_amount.replace('USD ', '')) / (location.state.plan.price / 100) / 100;
    setDiscountPercent(percent);
    setPromoCodeError('');
  };


  const { plan } = location.state;
  const {
    description, price, period, period_unit,
  } = plan;
  const totalPrice = price / 100;
  const totalPriceText = `${formatMoneyString(totalPrice)} / ${period > 1 ? period : ''} ${period_unit}`;

  const discountPrice = discountPercent ? totalPrice * discountPercent : 0;
  let discountPriceText = `${formatMoneyString(discountPrice)} / ${period > 1 ? period : ''} ${period_unit}`;

  if (discountPrice > 0) {
    discountPriceText = `-${discountPriceText}`;
  }
  const finalPrice = totalPrice - discountPrice;
  const finalPriceText = `${formatMoneyString(finalPrice)} / ${period > 1 ? period : ''} ${period_unit}`;


  if (!location.state || !location.state.plan) return <Redirect to="/" />;

  const renderLeftComponent = () => {
    const hasDiscount = discountPrice > 0;
    return (
      <div className="PaymentDetail__left">
        <div className="col-md-10 col-lg-12 m-md-auto">
          <h1>The Fitt Cycle</h1>
          <h2>{description}</h2>
          <div className="PaymentDetail__left__group">
            {hasDiscount && (
              <>
                <div className="PaymentDetail__left__group__row">
                  <span>Price</span>
                  <span>{totalPriceText}</span>
                </div>

                <div className="PaymentDetail__left__group__row">
                  <span>Promo</span>
                  <span>{discountPriceText}</span>
                </div>
              </>
            )}

            <div className="PaymentDetail__left__group__row">
              <span>{hasDiscount ? 'Total Price' : 'Price'}</span>
              <span>{finalPriceText}</span>
            </div>
          </div>

          <Input
            type="text"
            placeholder="Promo Code"
            onChange={onTextChange}
            error={promoCodeError}
            errorTextColor="white"
            RightComponent={searching && <span style={{ color: 'white' }} className="fa fa-refresh fa-spin loading" />}
          />


        </div>
      </div>
    );
  };

  const renderRightComponent = () => (
    <div className="PaymentDetail__right">
      <h1 className="title">Payment details</h1>
      <h2 className="subTitle">
        Add your Credit Card details below. We will process your payment and send your
        receipt to your account email.
      </h2>
      <PaymentForm
        onCheckout={onCheckout}
        buttonText="Complete Purchase"
        isProcessing={isProcessingPayment}
        paymentError={error}
        user={user}
      />

    </div>
  );
  return (
    <div className="PaymentDetail">
      <Header RightComponent={null} />
      <div className="container">
        <div className="PaymentDetail__form">
          <div className="row justify-content-between no-gutters">
            <div className="col-lg-4 col-md-12">{renderLeftComponent()}</div>
            <div className="col-lg-8 col-md-12">{renderRightComponent()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
