import { put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { appFetchingEnd, appFetchingStart } from 'Store/AppFetching/AppFetchingActions';
import { UPDATE_BIRTHDAY_REQUEST } from 'Store/User/UserActionTypes';


function* updateBirthdaySaga() {
  yield put(appFetchingStart());
  yield delay(1000);
  yield put(appFetchingEnd());
  yield put(push('/signup/3'));
}


export default function* signupWatcher() {
  yield takeLatest(UPDATE_BIRTHDAY_REQUEST, updateBirthdaySaga);
}
