import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';
import Header from 'Components/Header/Header';
import Button from 'Components/Button/Button';
import Input from 'Components/Input/Input';
import { getAppFetchingSelector } from 'Store/AppFetching/AppFetchingSelector';
import { signUpRequest } from 'Store/Auth/AuthActions';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { parseUrl } from 'Utils/Utils';
import { useInjectSaga } from 'redux-injectors';
import Cookies from 'js-cookie';
import saga from './SignUpSaga';
import './SignUpStyles.scss';

const key = 'signUp';

const SignUp = props => {
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const { location } = props;

  const isFetching = useSelector(getAppFetchingSelector);

  const onToggleVisiblePassword = () => setShowPassword(!showPassword);

  const onSubmit = values => {
    const { name, email, password } = values;
    const { fpr } = parseUrl(location.search);
    const fprom_track = Cookies.get('_fprom_track');
    const payload = {
      email,
      first_name: name,
      last_name: '',
      password,
      ref_id: fpr,
      firstpromoter_tid: fprom_track,
    };
    dispatch(signUpRequest(payload));
  };

  const renderForm = () => (
    <Formik
      initialValues={{ email: '', password: '', name: '' }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
        password: Yup.string().required('Required'),
      })}
      onSubmit={onSubmit}
      render={({
        handleChange, handleBlur, errors, handleSubmit,
      }) => {
        const disabled = !isEmpty(errors);

        const cn = showPassword ? 'fa fa-eye-slash' : 'fa fa-eye';

        return (
          <form className="SignUp__form" onSubmit={handleSubmit}>
            <h1>Create Your Account</h1>
            <h3>
              Join The Fitt Cycle today and begin working your way towards a healthier you!
            </h3>

            <Input
              className="SignUp__form__name"
              LeftComponent={<span className="fa fa-user" />}
              name="name"
              type="text"
              placeholder="First Name"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name}
            />
            <Input
              className="SignUp__form__email"
              LeftComponent={<span className="fa fa-envelope" />}
              name="email"
              type="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email}
            />
            <Input
              className="SignUp__form__pwd"
              LeftComponent={<span className="fa fa-lock left" />}
              RightComponent={<span onClick={onToggleVisiblePassword} className={cn} />}
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password}
            />
            <Button type="submit" loading={isFetching} disabled={disabled}>
              Get Started
            </Button>
          </form>
        );
      }}
    />
  );


  return (
    <div className="SignUp">
      <Header />
      <div className="container">
        <div className="row no-gutters justify-content-between SignUp__content">
          <div className="col-lg-6 col-sm-12 justify-content-end d-flex flex-column justify-content-center pb-sm-5">
            <h1>Let’s get this party started!</h1>
          </div>
          <div className="col-lg-5 lg-offset-1 col-sm-12 mr-sm-3 ml-sm-3">
            {renderForm()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
