import React, { useState, useRef } from 'react';
import Header from 'Components/Header/Header';
import Constants from 'Utils/Constants';
import Button from 'Components/Button/Button';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { Collapse } from 'reactstrap';
import { getUserName, formatMoneyString } from 'Utils/Utils';
import { getPlanSelector } from 'Store/Plan/PlanSelector';
import { getUserSelector } from 'Store/User/UserSelector';
import { UserInfo, CBPlan } from 'Types';
import TextTransition, { presets } from 'react-text-transition';
import Measure from 'react-measure';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PaymentPlanStyles.scss';


export default props => {
  const plans = useSelector(getPlanSelector);

  const user: UserInfo = useSelector(getUserSelector);
  const sliderRef = useRef(null);

  const [planIndex, setPlanIndex] = useState(0);
  const [collapse, setCollapse] = useState(new Array(3).fill(false));
  const { history } = props;
  const [textBounds, setTextBounds] = useState(null);
  const [testimonialIndex, setTestimonialIndex] = useState(0);


  const toggleMenu = index => {
    const newState = collapse.slice();
    newState[index] = !collapse[index];
    setCollapse(newState);
  };

  const onIndexChanged = index => {
    const plan = plans[index];
    if (index === planIndex) {
      history.push('/payment/detail', { plan });
    } else {
      setPlanIndex(index);
    }
  };

  const onSelectPlan = index => {
    const plan = plans[index];

    history.push('/payment/detail', { plan });
    setPlanIndex(index);
  };

  const renderPaymentSelector = () => {
    if (!Array.isArray(plans)) {
      return null;
    }
    return (
      <div className="PaymentPlan__page1__plan">
        {plans.map((item: CBPlan, index) => {
          const active = index === planIndex;
          const btnText = active ? 'Current Plan' : 'Select Plan';
          const {
            name, id, description, period, period_unit, price,
          } = item;
          const activeClass = active ? 'active' : '';
          const priceText = `per ${period > 1 ? period : ''} ${period_unit}`;
          const planPriceName = `${name} ${formatMoneyString(price / 100)}`;
          return (
            <div
              key={id}
              className={`col no-gutters PaymentPlan__page1__plan__card ${activeClass}`}
              onClick={() => onIndexChanged(index)}
            >
              <div className={`col justify-content-center no-gutters ${activeClass}`}>
                <h1 className={activeClass}>{planPriceName}</h1>
                <h2 className={activeClass}>{priceText}</h2>
                <h2 className={activeClass}>{description}</h2>

              </div>
              <Button
                onClick={() => onSelectPlan(index)}
                type="button"
              >
                {btnText}

              </Button>
            </div>
          );
        })}
      </div>
    );
  };

  const renderPageInfo = () => {
    const { target_weight, weight } = user;
    const name = getUserName(user);
    const delta = target_weight - weight;


    return (
      <div className="PaymentPlan__page1__top">
        <h1>{`${name}, ready to acheive those goals?`}</h1>
        <h2>{`${delta > 0 ? 'Gain' : 'Lose'} ${Math.abs(delta).toFixed(1)}lbs and make your target of ${target_weight}lbs.`}</h2>
        <h3>
          Get access to The FITT Cycle and join thousands of happy members! Get instant access to your specific carb cycling macros, daily workouts, meals, recipes, and social community to achieve your goals!
        </h3>
        <br />
        <h3>
          Start today.
        </h3>
      </div>
    );
  };

  const renderFirstPage = () => (
    <div className="PaymentPlan__page1">
      <Header RightComponent={null} />
      <div className="container">
        <div className="row no-gutters">
          <div className="col-lg-6 offset-lg-0 col-10 offset-1">{renderPageInfo()}</div>
          <div className="col-lg-4 offset-lg-2 col-10 offset-1">{renderPaymentSelector()}</div>
        </div>
        <div className="PaymentPlan__page1__bottom">
          <h1 className="title">TESTIMONIALS</h1>
          <h2 className="subTitle">Join thousands of happy members like those below.</h2>
        </div>
      </div>
    </div>
  );

  const renderSecondPage = () => {
    const data = Constants.TESTIMONIALS;
    const left = textBounds ? textBounds.left : 0;
    const name = data[testimonialIndex].name;
    const text = data[testimonialIndex].text;

    return (
      <div className="PaymentPlan__page2">
        <div style={{ paddingLeft: left }}>
          <Slider
            ref={sliderRef}
            className="slider variable-width"
            dots={false}
            infinite
            slidesToShow={1}
            slidesToScroll={1}
            variableWidth
            adaptiveHeight
            afterChange={currentSlide => setTestimonialIndex(currentSlide)}

          >
            {data.map((item, index) => (
              <div key={index}>
                <img
                  alt="The Fitt Cycle"
                  className="img-fluid PaymentPlan__page2__img"
                  src={item.image}
                />
              </div>
            ))}

          </Slider>
        </div>


        <div className="container PaymentPlan__page2__group">
          <div className="d-flex flex-row justify-content-between">
            <div className="container PaymentPlan__page2__group__title">
              <TextTransition
                text={name}
                springConfig={presets.slow}
                noOverflow
              />

            </div>
            <div className="PaymentPlan__page2__group__icon">
              <i
                className="fa fa-chevron-left fa-2x icon"
                aria-hidden="true"
                onClick={() => sliderRef.current.slickPrev()}
              />
              <i
                className="fa fa-chevron-right fa-2x icon"
                aria-hidden="true"
                onClick={() => sliderRef.current.slickNext()}
              />
            </div>
          </div>

          <Measure
            bounds
            onResize={contentRect => setTextBounds(contentRect.bounds)}
          >
            {({ measureRef }) => (
              <div ref={measureRef} className="col-md-8 no-gutters PaymentPlan__page2__group__subtitle">
                <TextTransition
                  text={text}
                  springConfig={presets.default}
                  direction="up"
                  noOverflow
                />
              </div>
            )}
          </Measure>

        </div>
      </div>
    );
  };

  const renderThirdPage = () => (
    <div className="PaymentPlan__page3">
      <div className="container">
        <div>
          <div className="PaymentPlan__page3__title">Frequently asked questions</div>
          <div className="PaymentPlan__page3__questionaire">
            {Constants.DUMMY_QUESTIONS.map((item, index) => {
              const { text, answers } = item;
              const isOpen = collapse[index];
              const borderClass = isOpen ? 'borderBottom' : '';
              const toggle = () => toggleMenu(index);
              return (
                <div key={index}>
                  <div
                    onClick={toggle}
                    className="PaymentPlan__page3__question d-flex flex-row align-items-center"
                  >
                    <div>{text}</div>
                    <i className="fa fa-chevron-down" aria-hidden="true" />
                  </div>
                  <Collapse isOpen={isOpen}>
                    <div className={`PaymentPlan__page3__answer ${borderClass}`}>
                      {answers.map((answer, idx) => (
                        <div key={idx}>{answer}</div>
                      ))}
                    </div>
                  </Collapse>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {renderFirstPage()}
      {renderSecondPage()}
      {renderThirdPage()}
    </>
  );
};
