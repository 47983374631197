import { createAction } from 'redux-actions';
import LeadActionTypes from './LeadActionTypes';

export const createLeadRequest = createAction(LeadActionTypes.CREATE_LEAD_REQUEST);
export const createLeadSuccess = createAction(LeadActionTypes.CREATE_LEAD_SUCCESS);
export const createLeadFailure = createAction(LeadActionTypes.CREATE_LEAD_FAILURE);

export const trackingSaleRequest = createAction(LeadActionTypes.TRACKING_SALE_REQUEST);
export const trackingSaleSuccess = createAction(LeadActionTypes.TRACKING_SALE_SUCCESS);
export const trackingSaleFailure = createAction(LeadActionTypes.TRACKING_SALE_FAILURE);

export default {
  createLeadRequest,
  createLeadSuccess,
  createLeadFailure,
  trackingSaleRequest,
  trackingSaleSuccess,
  trackingSaleFailure,
};
