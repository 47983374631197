import createReducer from 'Utils/CreateReducer';
import { APP_LOADED_SUCCESS } from './AppLoadingActionTypes';

// eslint-disable-next-line no-unused-vars
function appLoadedSuccess(state, action) {
  state = false;
  return state;
}

const appLoadingReducer = createReducer(null, {
  [APP_LOADED_SUCCESS]: appLoadedSuccess,
});

export default appLoadingReducer;
