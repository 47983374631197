import React, { useState } from 'react';
import Button from 'Components/Button/Button';
import Header from 'Components/Header/Header';
import ButtonLogout from 'Components/Button/ButtonLogout';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectSaga } from 'redux-injectors';
import { getAppFetchingSelector } from 'Store/AppFetching/AppFetchingSelector';
import { getUserSelector } from 'Store/User/UserSelector';
import { updateProfileRequest } from 'Store/User/UserActions';
import saga from './SignUp6Saga';
import './SignUp6Styles.scss';

const key = 'signup6';

export default () => {
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const isFetching = useSelector(getAppFetchingSelector);
  const user = useSelector(getUserSelector);
  const defaultTargetWeight = user && typeof user.target_weight === 'string' && user.targetWeight !== '' ? parseFloat(user.target_weight).toFixed(0) : '125';
  const [targetWeight, setTargetWeight] = useState(defaultTargetWeight);
  const isInvalid = typeof targetWeight !== 'string' || targetWeight === '';

  const onContinue = async event => {
    event.preventDefault();
    const newUser = {
      ...user, target_weight: targetWeight,
    };
    dispatch(updateProfileRequest(newUser));
  };


  return (
    <div className="SignUp6">
      <Header RightComponent={<ButtonLogout />} />
      <div className="container">
        <div className="col-lg-5 col-md-8 col-sm-12 p-sm-2 align-self-center mx-auto">
          <h6>Step 6 of 6</h6>
          <h1>What’s your target weight?</h1>
          <div className="d-flex flex-column align-items-center SignUp6__control">
            <div className="row justify-content-center align-items-end ">
              <input
                name="weight"
                onChange={({ target }) => setTargetWeight(target.value)}
                size={3}
                maxLength={3}
                placeholder="120"
                type="text"
                pattern="[0-9]*"
                defaultValue={targetWeight}
              />
              <span>LBS</span>
            </div>
            {isInvalid && <div className="mt-2 mx-auto input-error">Required</div>}
          </div>
          <Button
            type="button"
            onClick={onContinue}
            loading={isFetching}
            disabled={isInvalid}
          >
          Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
