import Axios from 'axios';
import Constants from 'Utils/Constants';

const FirstPromoterInstance = Axios.create({
  baseURL: Constants.FIRST_PROMOTER_BASE_URL,
  timeout: 60000,
  headers: {
    'x-api-key': Constants.FIRST_PROMOTER_API_KEY,
  },
});

FirstPromoterInstance.interceptors.request.use(async config => {
  if (Constants.IS_STAGING) {
    const tag = 'Request'.padEnd(16);
    const method = `[${config.method.toUpperCase()}]`.padEnd(10);
    const url = `${config.baseURL}${config.url}`;
    const title = `${tag}${method}${url}`;

    console.group(`%c ${title}`, ...['color: #03A9F4; font-weight: bold;']);

    config.headers && console.log('Headers'.padEnd(14), config.headers);
    config.params && console.log('Param'.padEnd(14), config.params);
    config.data && console.log('Body'.padEnd(14), config.data);
    console.groupEnd();
  }
  return config;
});

FirstPromoterInstance.interceptors.response.use(
  response => {
    if (Constants.IS_STAGING) {
      const tag = 'Request Success'.padEnd(16);
      const method = `[${response.config.method.toUpperCase()}]`.padEnd(10);
      const url = `${response.config.url}`;
      const title = `${tag}${method}${url}`;
      console.groupCollapsed(
        `%c ${title}`,
        ...['color: #4CAF50; font-weight: bold;'],
      );
      response.config.headers
        && console.log('Headers'.padEnd(14), response.config.headers);
      response.config.params
        && console.log('Param'.padEnd(14), response.config.params);
      response.config.data
        && console.log('Body'.padEnd(14), response.config.data);
      response.data && console.log('Status Code'.padEnd(14), response.status);
      response.data && console.log('Response Data'.padEnd(14), response.data);
      console.groupEnd();
    }
    return response.data;
  },
  error => {
    if (error.response) {
      const errorData = error.response.data;
      const errorResponse = errorData && Array.isArray(errorData.errors) && errorData.errors.length
        ? errorData.errors[0]
        : null;
      error.message = errorResponse ? errorResponse.message : Constants.INTERNAL_ERROR_MESSAGE;
      error.code = errorResponse ? errorResponse.code : 500;
    }

    if (Constants.IS_STAGING) {
      const tag = 'Request Error'.padEnd(16);
      const method = `[${error.config.method.toUpperCase()}]`.padEnd(10);
      const url = `${error.config.url}`;
      const title = `${tag}${method}${url}`;
      console.group(`%c ${title}`, ...['color: #F20404; font-weight: bold;']);
      error.config.headers
        && console.log('Headers'.padEnd(14), error.config.headers);
      error.config.params
        && console.log('Param'.padEnd(14), error.config.params);
      error.config.data && console.log('Body'.padEnd(14), error.config.data);
      error.message && console.log('Error Message'.padEnd(14), error.message);
      error.code && console.log('Error Code'.padEnd(14), error.code);
      console.groupEnd();
    }

    return Promise.reject(error);
  },
);


export default FirstPromoterInstance;
