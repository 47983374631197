import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAppFetchingSelector } from 'Store/AppFetching/AppFetchingSelector';
import { getUserSelector } from 'Store/User/UserSelector';
import { updateBirthdayRequest } from 'Store/User/UserActions';
import { useInjectSaga } from 'redux-injectors';
import moment from 'moment';
import Button from 'Components/Button/Button';
import ButtonLogout from 'Components/Button/ButtonLogout';
import Header from 'Components/Header/Header';
import saga from './SignUp2Saga';
import './SignUp2Styles.scss';

const key = 'signup2';

export default () => {
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const isFetching = useSelector(getAppFetchingSelector);
  const user = useSelector(getUserSelector);
  let yearRequired = false;
  let monthRequired = false;
  let dayRequired = false;

  const birthday = user && typeof user.birthday === 'string' && user.birthday !== '' ? user.birthday : '1995-10-23';
  const temp = moment(birthday, 'YYYY-MM-DD').format('YYYY-MMM-DD');
  const [y, m, d] = temp.split('-');

  const [year, setYear] = useState(y);
  const [month, setMonth] = useState(m);
  const [day, setDay] = useState(d);

  const dateString = `${day}/${month}/${year}`;
  const isInvalid = moment(dateString, 'DD/MMM/YYYY').isValid() === false;

  const onContinue = event => {
    event.preventDefault();

    const value = moment(dateString, 'DD/MMM/YYYY').format('YYYY-MM-DD');
    dispatch(updateBirthdayRequest(value));
  };

  const hasValidDay = (dValue, mValue, yValue) => {
    const testDateString = `${yValue}-${mValue}`;
    const testDate = moment(testDateString, 'YYYY-MMM');
    if (!testDate.isValid()) {
      return null;
    }

    const dStr = `${dValue}/${mValue}/${yValue}`;
    let date = moment(dStr, 'DD-MMM-YYYY');
    if (!date.isValid()) {
      date = moment(testDateString, 'YYYY-MMM').endOf('month');

      if (date.isAfter(moment())) {
        return moment()
          .date()
          .toString();
      }
      return date.date().toString();
    }

    if (date.isAfter(moment())) {
      return moment()
        .date()
        .toString();
    }
    return null;
  };

  const renderMonth = () => {
    const months = moment.monthsShort();
    const required = month === '' && monthRequired;
    return (
      <div className="col-4 d-flex flex-column align-items-center">
        <input
          placeholder="Oct"
          className="form-control"
          value={month}
          maxLength={3}
          onChange={e => {
            let value = e.target.value;
            const length = value.length;
            const index = months.findIndex(item => {
              const valid = item.toLowerCase().slice(0, length) === value.toLowerCase();
              return valid;
            });
            if (index === -1) {
              return;
            }

            value = value.charAt(0).toUpperCase() + value.slice(1);

            const validDay = hasValidDay(day, value, year) || day;
            setMonth(value);
            setDay(validDay);
            monthRequired = true;
          }}
        />
        <span>MONTH</span>
        {required && <p className="input-error">Required</p>}
      </div>
    );
  };

  const renderDay = () => {
    const required = day === '' && dayRequired;

    return (
      <div className="col-4 d-flex flex-column align-items-center">
        <input
          className="form-control"
          placeholder="23"
          value={day}
          maxLength={2}
          pattern="[0-9]*"
          onChange={e => {
            const value = e.target.value;
            const dInt = parseInt(value, 10);
            if (value.length === 2 && dInt === 0) {
              setDay('01');
              return;
            }

            if (dInt > 31) {
              setDay('31');
              return;
            }

            const validDay = hasValidDay(value, month, year) || value;
            setDay(validDay);
            dayRequired = true;
          }}
        />
        <span>DAY</span>
        {required && <p className="input-error">Required</p>}
      </div>
    );
  };

  const renderYear = () => {
    const minYear = 1920;
    const minYearString = minYear.toString();
    const maxYear = new Date().getFullYear();
    const maxYearString = maxYear.toString();
    const required = year === '' && yearRequired;
    return (
      <div className="col-4 d-flex flex-column align-items-center">
        <input
          className="form-control"
          value={year}
          placeholder="1995"
          maxLength={4}
          pattern="[0-9]*"
          onChange={e => {
            const value = e.target.value;
            const length = value.length;

            const testMinYear = value + minYearString.slice(length);
            const testMinYearInt = parseInt(testMinYear, 10);

            if (testMinYearInt < minYear) {
              setYear(minYearString.slice(0, length));
              return;
            }

            const testMaxYear = value + maxYearString.slice(length);
            const testMaxYearInt = parseInt(testMaxYear, 10);

            if (testMaxYearInt > maxYear) {
              setYear(maxYearString);
              return;
            }
            const validDay = hasValidDay(day, month, value) || day;

            setYear(value);
            setDay(validDay);
            yearRequired = true;
          }}
        />
        <span>YEAR</span>
        {required && <p className="input-error">Required</p>}
      </div>
    );
  };


  return (
    <div className="SignUp2">
      <Header RightComponent={<ButtonLogout />} />
      <div className="container">
        <div className="col-lg-5 col-md-8 col-sm-12 p-sm-2 align-self-center mx-auto">
          <h6>Step 2 of 6</h6>
          <h1>When is your birthday?</h1>
          <div className="row no-gutters SignUp2__control">
            {renderMonth()}
            {renderDay()}
            {renderYear()}
          </div>
          <Button
            type="button"
            onClick={onContinue}
            loading={isFetching}
            disabled={isInvalid}
          >
              Continue
          </Button>
        </div>
      </div>
    </div>
  );
};
